import React, {useState} from "react";
import CustomInput from "../../common/input/customInput";
import ImagesUpload from "../../common/input/imagesUpload";
import axios from "axios";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import Spinner from 'react-bootstrap/Spinner';
import Compress from "compress.js";
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

function AddElaboration (props){

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [video, setVideo] = useState("");
    const [images, setImages] = useState("");
    const [charCounter, setCharCounter] = useState("0");
    const [feedback, setFeedback] = useState("");
    const [isSubmitted, setSubmitted] = useState(false);
    const limitChars = 600;

    const cookies = new Cookies();
    const cookieString = cookies.get('data');
    let decrypted = CryptoJS.decrypt(cookieString, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
    //data[0]=uid, data[1]=token, data[2]=username
    const uid = decrypted.split('%')[0];
   
    const changeTitle = (event) => {
        if(event.target.value.length < 60){
            setTitle(event.target.value);
        }
    }

    const changeDescription = (event) => {
        var text_length = event.target.value.length;
        if(text_length < limitChars){
            setDescription(event.target.value);
        }
        setCharCounter(text_length);
    }

    const changeImages = (files) => {
        setImages(files);
    }

    const changeVideo = (event) => {
        if(event.target.value.length < 200){
            setVideo(event.target.value);
        }
    }

    const dataURLtoFile = async (dataurl, filename) => {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }

    const compressFile = async (compress, fileimg) => {
        let comp = await compress.compress(fileimg, {
            size: 2, // the max size in MB, defaults to 2MB
            quality: .75, // the quality of the image, max is 1,
            maxWidth: 1920, // the max width of the output image, defaults to 1920px
            maxHeight: 1920, // the max height of the output image, defaults to 1920px
            resize: false, // defaults to true, set false if you do not want to resize the image width and height
            rotate: false, // See the rotation section below
            }).then( async (data) => {
            // returns an array of compressed images
            return [data[0].prefix+data[0].data, data[0].alt];
        });
        let urltofile = await dataURLtoFile(comp[0], comp[1]);
        return urltofile;
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        var toPost = new FormData();

        if(title.length >= 3 && description.length >=5){
            if(images && images.length > 0){
                let fileCounter = 0;
                if(images[0].length < 5){
                    for(let filex = 0; filex < images.length; filex++){
                        for(let filey = 0; filey < images[filex].length; filey++){
                            if(images[filex][filey] instanceof File){
                                fileCounter++;
                            }
                        }
                    }
                }
        
                if(images[0].length > 5 || fileCounter > 5){
                    localStorage.setItem('elab_title', title);
                    localStorage.setItem('elab_description', description);
                    alert("Puoi caricare al massimo 5 immagini");
                    window.location.replace("/elaborations/add");
                }else{
                    setSubmitted(true);
                    localStorage.removeItem('elab_title');
                    localStorage.removeItem('elab_description');
                    toPost.append('uid', uid);
                    toPost.append('title', title);
                    toPost.append('description', description);
                    toPost.append('video', video);
        
                    const compress = new Compress();
        
                    for (let fileList = 0; fileList < images.length; fileList++) {
                        for(var k in images[fileList]){
                            if(k.length < 3){
                                let compressedFile = await compressFile(compress, [images[fileList][k]]);
                                toPost.append('image_'+k, compressedFile);
                            }
                        }
                    }
                    
                    let headers = getHeadersAuthorization(cookieString);
                    headers['Content-Type'] = 'multipart/form-data';
                    const config = {
                        headers: headers
                    };
                    axios.post(process.env.REACT_APP_API_HOST + "elaborations/insert", toPost, config)
                        .then((response) => {
                            window.location.replace("/");
                        }).catch((error) => {
                    });
                }
            }else{
                setFeedback("Devi caricare almeno un'immagine");
            }
        }else if(title.length < 5){
            setFeedback("Il titolo deve essere di almeno 3 caratteri");
        }else if(description.length < 10){
            setFeedback("La descrizione deve essere di almeno 5 caratteri");
        }
    }
    
    return(
        <div className="main-box">
            <div className = "container">
                <div className = "row">
                    <div className = "col-lg-6 col-md-12 mr-auto ml-auto">
                        <form onSubmit={onSubmit.bind(this)}>
                            <h2 className="form-title mb-4">Inserisci nuova elaborazione</h2>
                            <div className = "form-group">
                                <CustomInput 
                                    type="text"
                                    name="title"
                                    label="Titolo"
                                    placeholder="Titolo elaborazione"
                                    value = {title || '' || localStorage.getItem('elab_title')}
                                    onChange = {changeTitle.bind(this)}
                                    />
                            </div>
                            <div className = "form-group">
                                <textarea
                                    name="description"
                                    className="form-control"
                                    placeholder="Descrivi la tua elaborazione"
                                    style={{height: "130px"}}
                                    value = {description || '' || localStorage.getItem('elab_description')}
                                    onChange = {changeDescription.bind(this)}
                                />
                                <div className="char-counter text-muted float-right">{charCounter}/{limitChars}</div>
                            </div>
                            <div className = "form-group">
                                <CustomInput 
                                    type="text"
                                    name="video"
                                    label="Video"
                                    placeholder="incolla URL video Youtube"
                                    value = {video || ''}
                                    onChange = {changeVideo.bind(this)}
                                    />
                            </div>
                            <div className="form-group">
                                <ImagesUpload onEdit={changeImages.bind(this)} />
                            </div>
                            {!isSubmitted ? (
                                <button 
                                    type="submit"
                                    className = "btn btn-primary">
                                    Crea
                                </button>
                            ) : (
                                <Spinner className="disc-name-color" animation="border" role="status">
                                    <span className="d-none">Loading...</span>
                                </Spinner>
                            )}
                        </form>
                        <div className="mt-2" style={{color: 'red'}}>
                            {feedback}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
}
/* action="/elaborations/insert" method="POST" encType="multipart/form-data"  */
export default AddElaboration;
