import React from "react";
import "../../../css/mainLayout/header/header.css";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import elabLogo from "../../../images/elab-icon.png";
import discLogo from "../../../images/disc-icon.png";
import RaceDesktopLogo from '../../../images/logo-white-flag.png';
import RaceMobileLogo from '../../../images/r-mobile-logo.png';
import axios from "axios";
import {isMobile} from 'react-device-detect';
import YouMustBeLogged from '../../modals/youmustbelogged';
import { getHeadersAuthorization } from '../../utilityFunctions/apiheadersauthorization';

export default class Header extends React.Component{ 

   constructor(props){
      super(props);

      this.state = {
         isAdmin: false,
      }

      this.cookies = new Cookies();
      this.data = '';
      this.config = null;

      let cookie = this.cookies.get('data');

      if(cookie){
         this.config = {
            headers: getHeadersAuthorization(cookie)
        };
         this.data = CryptoJS.decrypt(cookie, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8).split('%');
         //data[0]=uid, data[1]=token, data[2]=username
      }
   }

   clearStorage(event){
      event.preventDefault();
      this.cookies.remove('data', { path: '/' });
      window.location.replace("/login");
   }

   componentDidMount() {
      const toPost={
         uid: this.data[0]
     };

     axios.post(process.env.REACT_APP_API_HOST + "user/isadmin", toPost, this.config)
     .then((response) => {
         this.setState({isAdmin: response.data.items});
     }).catch((error) => {
     });
   }

   isLogged(token){
      if(token){
         //se è loggato, tasto esci
         return(<div className = "navbar-nav">
                  <li className = "nav-item dropdown">
                     {/* eslint-disable-next-line */}
                     <a className = "nav-link nav-item dropdown-toggle username sixth-step" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div className="username-menu">
                           {this.data[2]}
                        </div>
                     </a>
                     <div className = "dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        {/* eslint-disable-next-line */}
                        {this.state.isAdmin ? (<a className = "dropdown-item" href="/admin">Lato Admin</a>) : ('')}
                        <a className = "dropdown-item" href="/user/posts">I miei post</a>
                        <a className = "dropdown-item" href="/user/details">Visualizza account</a>
                        <a className = "dropdown-item" href="/contactus">Contattaci</a>
                        {/* eslint-disable-next-line */}
                        <a className = "dropdown-item text-danger" href="#" onClick={this.clearStorage.bind(this)}>Esci</a>
                     </div>
                  </li>
               {/* <a href="/user/details" className="nav-item nav-link username">{this.data[2]}</a>
               <button type="button" className = "btn btn-danger" onClick={this.clearStorage.bind(this)}>Esci</button> */}
               </div>);
      }else{
         //se non è loggato, tasti login e registrati
         return(<div className = "navbar-nav"><a className="nav-item nav-link" href="/login">Login</a>
         <a className="nav-item nav-link" href="/register">Registrati</a></div>);
      }
   }

   whoIsActive(){
      let url = window.location.href.split('/');
      if(url[3].length === 0 || url[3] === 'elaborations'){
         return [<div className="menu-active"></div>, ''];
      }else if(url[3] === 'discussions'){
         return ['', <div className="menu-active"></div>];
      }else{
         return ['', ''];
      }
   }

   render(){ 
      const toDisplay = this.isLogged(this.data[1]);
      const isActive = this.whoIsActive();

      return( 
         <div>
            <nav className="navbar fixed-top navbar-expand navbar-light navbar-custom">
               <a className="navbar-nav navbar-brand center-brand first-step" href="/hpcalculator">
                  {!isMobile ? (
                     <img src={RaceDesktopLogo} width={'150px'} alt="race-logo"></img>
                  ) : (
                     <img src={RaceMobileLogo} width={'40px'} alt="race-logo"></img>
                  )}
               </a>
               {/* <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
               <span className="navbar-toggler-icon"></span>
               </button>
               <div className="collapse navbar-collapse" id="navbarNavAltMarkup"> */}
                  <div className="navbar-nav mr-auto second-step">
                     {
                        this.data[1] ? (
                           <>
                           <a className="nav-item nav-link" href="/" style={{paddingBottom: isActive[0]!=='' ? '0px' : '8px'}}>
                              <img className="third-step" src={elabLogo} width="35px" height="35px" alt="elab-logo"/>
                              {isActive[0]}
                           </a>

                           <a className="nav-item nav-link" href="/discussions" style={{paddingBottom: isActive[1]!=='' ? '0px' : '8px'}}>
                              <img className="fourth-step" src={discLogo} width="35px" height="35px" alt="disc-logo"/>
                              {isActive[1]}
                           </a>
                           </>
                        ):(
                           <>
                           <YouMustBeLogged section={'Elaborazioni'} />
                           <YouMustBeLogged section={'Discussioni'} />
                           </>
                        )
                     }
                  </div>
                  {/* <div className = "navbar-nav wrapper-stima-cv">
                     <a href="/hpcalculator" className="btn btn-dark btn-stima-cv">Stima cv✨</a>
                  </div> */}
                  {toDisplay}
               {/* </div> */}
            </nav>
            
         </div>
      ); 
   }
}