import axios from "axios";
export default class ForgotPwService{
   forgotPw(email, onSuccess, onError){
      axios.post(process.env.REACT_APP_API_HOST + "user/forgotpw", {email: email})
      .then(function(result){
         //console.log(result.data);
         onSuccess(result.data);
      }, function(error){
         //console.log("Errore durante il login: ", error.response.data);
         onError(error.response.data);
      });
   }

}