import React from "react";
import "../../../css/mainLayout/footer/footer.css";

export default class Footer extends React.Component{ 

   render(){ 
      return( 
         <footer className="footer">
            <div className="container" style={{textAlign: "center"}}>
               <div className="socials pt-5">
                  <div className="social-links">
                     <a href="https://www.instagram.com/racemood.it" target="_blank" rel="noreferrer"><i className="fa fa-instagram fa-lg mt-2"></i></a>
                     <a href="https://fb.me/racemood" target="_blank" rel="noreferrer" style={{margin: "15px"}}><i className="fa fa-facebook fa-lg mt-2"></i></a>
                     <a href="https://www.tiktok.com/@racemood.it" target="_blank" rel="noreferrer"><i className="fab fa-tiktok fa-lg mt-2"></i></a>
                  </div>
               </div>
               <div className="mt-4">
                  <div className="footer-titles">Link Utili</div>
                  <div className="utility-links">
                     <a href="/docs/termini_e_condizioni.pdf" target="_blank">Termini e condizioni</a><br/>
                     <a href="/docs/privacy_policy.pdf" target="_blank">Politica sulla privacy</a><br/>
                     <a href="/docs/cookie_policy.pdf" target="_blank">Politica sui cookie</a>
                  </div>
               </div>
               <div className="mt-4 pb-4">
                  <a className="footer-font-color racemood-brand-font" href="/">
                     © {new Date().getFullYear()} RACEMOOD
                  </a>
                  <div className="footer-help">
                     Per assistenza: <a href="mailto:info@racemood.it">info@racemood.it</a>
                  </div>
               </div>
            </div>
         </footer>
      ); 
   }
}