import axios from "axios";
export default class RegisterService{
   register(username, email, password, onSuccess, onError){
      axios.post(process.env.REACT_APP_API_HOST + "register/insertuser", {username: username, email: email, password: password})
      .then(function(result){
         onSuccess(result.data);
      }, function(error){
         onError(error.response.data);
      });
   }

}