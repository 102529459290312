import React, {useState, useEffect} from "react";
// import CustomInput from "../../common/input/customInput";
import axios from "axios";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import EditPost from '../modals/editpost';
import DeletePost from '../modals/deletepost';
import Spinner from 'react-bootstrap/Spinner';
import tankImg from "../../images/fuel-tank.png";
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

function UserPosts (props){

    const CLOSED_CARD = 'closed-card';
    const OPENED_CARD = 'opened-card';
    const [elabPayload, setElabPaylaod] = useState(null);
    const [discPayload, setDiscPaylaod] = useState(null);
    const [elabComments, setElabComments] = useState([]);
    const [discComments, setDiscComments] = useState([]);
    const [elabLoading, setElabLoading] = useState(true);
    const [discLoading, setDiscLoading] = useState(true);

    const cookies = new Cookies();
    const cookieString = cookies.get('data');
    let decrypted = CryptoJS.decrypt(cookieString, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
    //data[0]=uid, data[1]=token, data[2]=username
    const uid = decrypted.split('%')[0];

    useEffect(() => {
        let config = {
            headers: getHeadersAuthorization(cookieString)
        }

        async function fetchUserPosts() {
            axios.post(process.env.REACT_APP_API_HOST + "user/getposts", {uid: uid}, config)
            .then((response) => {
                //elab fill
                let elaborations = response.data.elab;
                for(let i=0;i<elaborations.length;i++){
                    elaborations[i].showComments = false;
                }
                setElabPaylaod(elaborations);
                setElabLoading(false);
                setElabComments(response.data.ecomments);
                //disc fill
                let discussions = response.data.disc;
                for(let i=0;i<discussions.length;i++){
                    discussions[i].showComments = false;
                }
                setDiscPaylaod(discussions);
                setDiscLoading(false);
                setDiscComments(response.data.dcomments);
            }).catch((error) => {
                if (error.response.status === 401) {
                    //devo ri-instanziare Cookies perché ancora non esiste
                    let cook = new Cookies();
                    cook.remove('data', { path: '/' });
                    window.location.replace("/login");
                }
            });
        }
        fetchUserPosts()
      }, [uid, cookieString]);
    

    const showComments = (whois, itemid) => {
        if(whois === 'ELAB'){
            let appo = elabPayload.slice();
            for(let i=0;i<appo.length;i++){
                if(appo[i].id === itemid){
                    appo[i].showComments = !appo[i].showComments;
                }
            }
            setElabPaylaod(appo);
        }else if(whois === 'DISC'){
            let appo = discPayload.slice();
            for(let i=0;i<appo.length;i++){
                if(appo[i].id === itemid){
                    appo[i].showComments = !appo[i].showComments;
                }
            }
            setDiscPaylaod(appo);
        }
    }

    const PrintComments = (vars) => {
        
        let posts = vars.posts;
        let current = vars.current;
        let whois =  vars.whois;
        let toPrint = [];
        let subject = ( whois === 'ELAB' ? 'commenti' : 'risposte');
        let printedButton = false;
        
        for (let i = 0; i < posts.length; i++) {
            if (posts[i].id_post === current.id) {
                if(!printedButton){
                    toPrint.push(<center key={'a'+i}><button className="btn btn-link mt-1" onClick={showComments.bind(this, whois, current.id)}>{(current.showComments ? 'Nascondi '+subject : 'Vedi '+subject)}</button></center>);
                    printedButton = true;
                }
                toPrint.push(<div key={'b'+i}>
                                <hr/>
                                <h6>{posts[i].username}</h6>
                                {posts[i].description}
                            </div>);
            }
        }

        return toPrint;
    }

    return(
        <div className="main-box">
            <div className = "container">
                <div className = "row">
                    <div className = "col-md-6 mr-auto ml-auto">
                        {elabLoading || !elabPayload ? (
                            <div className="item-centered">
                                <h2>I miei post</h2>
                                <Spinner className="ml-2 disc-name-color" animation="border" role="status">
                                    <span className="d-none">Loading...</span>
                                </Spinner>
                            </div>
                        ) : (
                            <div>
                                <center><h2>I miei post ({elabPayload.length})</h2></center>
                                {elabPayload.map(item => (
                                    <div key={item.id} className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">{item.title}</h5>
                                            <p className="card-text">{item.description}</p>
                                            <p className="card-text"><img src={tankImg} width="35px" height="35px" alt="tank"/> {item.likes} ottani</p>
                                            <EditPost id={item.id} title={item.title} description={item.description} type={'ELAB'} customclass="mr-2"/>
                                            <DeletePost id={item.id} title={item.title} type={'ELAB'}/><br/>
                                            <div className={(item.showComments ? OPENED_CARD : CLOSED_CARD) + ' custom_scroll'}>
                                                <PrintComments posts={elabComments} current={item} whois={'ELAB'} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className = "col-md-6 mr-auto ml-auto">
                        {discLoading || !discPayload ? (
                            <div className="item-centered">
                                <h2>Le mie domande</h2>
                                <Spinner className="ml-2 disc-name-color" animation="border" role="status">
                                    <span className="d-none">Loading...</span>
                                </Spinner>
                            </div>
                        ) : (
                            <div>
                                <center><h2>Le mie domande ({discPayload.length})</h2></center>
                                {discPayload.map(item => (
                                    <div key={item.id} className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">{item.title}</h5>
                                            <p className="card-text">{item.description}</p>
                                            <EditPost id={item.id} title={item.title} description={item.description} type={'DISC'} customclass="mr-2"/>
                                            <DeletePost id={item.id} title={item.title} type={'DISC'}/>
                                            <div className={(item.showComments ? OPENED_CARD : CLOSED_CARD) + ' custom_scroll'}>
                                                <PrintComments posts={discComments} current={item} whois={'DISC'} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default UserPosts;
