import React, {useState} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import MainTemplate from "./components/mainLayout/template/mainTemplate";
import Home from "./components/home/home";
import AddElaboration from "./components/home/addElaboration";
import Discussions from "./components/discussions/discussions";
import AddDiscussion from "./components/discussions/addDiscussion";
import Login from "./components/login/login";
import Register from './components/register/register';
import UserValidate from './components/validate/uservalidate';
import ChangePassword from './components/validate/changepassword';
import ForgotPassword from './components/forgotpassword/forgotpassword';
import UserPosts from './components/user/userposts';
import UserDetails from './components/user/userdetails';
import ContactUs from './components/user/contactus';
import HpCalculator from './components/hpcalculator/hpcalculator';
import HpCalculatorApprover from './components/hpcalculator/hpcalculatorapprover';
import AddEngines from './components/hpcalculator/addengines';
import AddExistingEngines from './components/hpcalculator/addexistingengines';
import AdminPage from './components/hpcalculator/adminpage';
import ElaborationSteps from './components/hpcalculator/elaborationsteps';
import NotFoundPage from './components/errorPages/notfoundpage'
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';

function App() {

  const cookies = new Cookies();
  let cookie = cookies.get('data');
  let tokenVar = '';
  if(cookie){
    let decrypted = CryptoJS.decrypt(cookie, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
    tokenVar = decrypted.split('%')[1];
  } 
  const [token] = useState(tokenVar);

  if(!token) {
    return( 
      <BrowserRouter>
        <Switch>
          <Route exact path='/login'                component={Login} />
          <Route exact path='/register'             component={Register} />
          <Route exact path='/user/validate/:token' component={UserValidate} />
          <Route exact path='/forgotPassword'       component={ForgotPassword} />
          <Route exact path='/changepw/:token'      component={ChangePassword} />
          <Route exact path='/contactus'            component={ContactUs} />
          <Route exact path='/hpcalculator'         component={HpCalculator} />
          <Route exact path='/*'                    component={Login} />
        </Switch>
      </BrowserRouter>
    );
  }
  
  return (
    <BrowserRouter>
      <MainTemplate>
          <Switch>
            <Route exact path='/'                     component={() => <Home visitor={false} />} />
            <Route exact path='/elaborations/add'     component={AddElaboration} />
            <Route exact path='/discussions'          component={Discussions} />
            <Route exact path='/discussions/add'      component={AddDiscussion} />
            <Route exact path='/user/posts'           component={UserPosts} />
            <Route exact path='/user/details'         component={UserDetails} />
            <Route exact path='/contactus'            component={ContactUs} />
            <Route exact path='/admin'                component={AdminPage} />
            <Route exact path='/hpcalculator'         component={HpCalculator} />
            <Route exact path='/hpcalculatorapprover' component={HpCalculatorApprover} />
            <Route exact path='/addengine'            component={AddEngines} />
            <Route exact path='/addexistingengine'    component={AddExistingEngines} />
            <Route exact path='/admin'                component={AdminPage} />
            <Route exact path='/elaborationstages'    component={ElaborationSteps} />
            <Route exact path='/*'                    component={NotFoundPage} />
          </Switch>
      </MainTemplate>
    </BrowserRouter>
  );

  /*<Route exact path='/login' component={Login} />
    <Route exact path='/register' component={Register} />
    render={(props) => (<Login {...props} userToken={setToken} uname={setUsername} />)}
    <Route exact path='/'         component={() => <Login userToken={setToken} />} />
    <Route exact path='/login'    component={() => <Login userToken={setToken} />} />
    */
}

export default App;