import React, {useState} from "react";
import CustomInput from "../../common/input/customInput";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import Spinner from 'react-bootstrap/Spinner';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

function AddDiscussion (props){

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [feedback, setFeedback] = useState("");
    const [charCounter, setCharCounter] = useState("0");
    const [isSubmitted, setSubmitted] = useState(false);
    const limitChars = 600;

    const cookies = new Cookies();
    const cookieString = cookies.get('data');
    let decrypted = CryptoJS.decrypt(cookieString, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
    //data[0]=uid, data[1]=token, data[2]=username
    const uid = decrypted.split('%')[0];
   
    const changeTitle = (event) => {
        if(event.target.value.length < 60){
            setTitle(event.target.value);
        }
    }

    const changeDescription = (event) => {
        var text_length = event.target.value.length;
        if(text_length < limitChars){
            setDescription(event.target.value);
        }
        setCharCounter(text_length);
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if(title.length >= 3 && description.length >=5){
            setSubmitted(true);
            const toPost={
                uid: uid,
                title: title,
                description: description,
                created: new Date()
            };
            const url = process.env.REACT_APP_API_HOST + "discussions/insert";
            let headers = getHeadersAuthorization(cookieString);
            headers['Content-Type'] = 'application/json';
            const response= await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(toPost)
            }); 
            const data = await response.json();
            setFeedback(data.response);
            if (data.response === 'success') {
                window.location.replace("/discussions")
            }
        }else if(title.length < 5){
            setFeedback("Il titolo deve essere di almeno 5 caratteri");
        }else if(description.length < 10){
            setFeedback("La descrizione deve essere di almeno 10 caratteri");
        }
    }
    
    return(
        <div className="main-box">
            <div className = "container">
                <div className = "row">
                    <div className = "col-lg-6 col-md-12 mr-auto ml-auto">
                        <form onSubmit={onSubmit.bind(this)}>
                        <h2 className="form-title mb-4">Inserisci nuova domanda</h2>
                            <div className = "form-group">
                                <CustomInput 
                                    type="text"
                                    name="title"
                                    label="Titolo"
                                    placeholder="Titolo della domanda"
                                    value = {title || ''}
                                    onChange = {changeTitle.bind(this)}
                                    />
                            </div>
                            <div className = "form-group">
                                <textarea 
                                name="description"
                                className="form-control"
                                placeholder="Descrivi per bene la tua domanda"
                                style={{height: "130px"}}
                                value = {description || ''}
                                onChange = {changeDescription.bind(this)}
                                />
                                <div className="char-counter text-muted float-right">{charCounter}/{limitChars}</div>
                            </div>
                            {!isSubmitted ? (
                                <button 
                                    type="submit"
                                    className = "btn btn-primary">
                                    Crea
                                </button>
                            ) : (
                                <Spinner className="disc-name-color" animation="border" role="status">
                                    <span className="d-none">Loading...</span>
                                </Spinner>
                            )}
                        </form>
                        {feedback && feedback!=='success' ? (
                            <div className="alert alert-danger">
                                {feedback}
                            </div>
                        ):('')}
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default AddDiscussion;
