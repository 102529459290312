import React, {useState, useEffect} from "react";
import axios from "axios";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import Header from "../mainLayout/header/header";
import Footer from "../mainLayout/footer/footer";
import Spinner from 'react-bootstrap/Spinner';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

function AddExistingEngines (props){

    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [engines, setEngines] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(0);
    const [selectedModel, setSelectedModel] = useState(0);
    const [selectedEngine, setSelectedEngine] = useState('select');
    const [feedback, setFeedback] = useState('');
    const [feedbackType, setfeedbackType] = useState('');
    const [isAdmin, setIsAdmin] = useState('loading');

    const cookies = new Cookies();
    let cookie = cookies.get('data');
    let decrypted = '';
    let uid = null;
    let config = null;

    if(cookie !== undefined){
        config = {
            headers: getHeadersAuthorization(cookie)
        };

        decrypted = CryptoJS.decrypt(cookie, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
        //data[0]=uid, data[1]=token, data[2]=username
        uid = decrypted.split('%')[0];
    }

    useEffect(() => {
        const toPost={uid: uid};
        axios.post(process.env.REACT_APP_API_HOST + "user/isadmin", toPost, config)
        .then((response) => {
            setIsAdmin(response.data.items);
        });

        axios.post(process.env.REACT_APP_API_HOST + "car/getallbrands", [], config)
        .then((response) => {
            setBrands(response.data.items);
        }).catch((error) => {
        });

        axios.post(process.env.REACT_APP_API_HOST + "car/getallengines", [], config)
        .then((response) => {
            setEngines(response.data.items);
        }).catch((error) => {
        });

    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();
        const toPost={
            uid: uid,
            model: selectedModel,
            engine: selectedEngine
        };

        if(selectedBrand !== 0 && selectedModel !== 0){
            if(selectedEngine !== 'select'){
                axios.post(process.env.REACT_APP_API_HOST + "car/insertexistingengine", toPost, config)
                    .then((response) => {
                        if(response.data.response==="SUCCESS"){
                            setSelectedEngine('select');
                            setFeedback("Motore inserito con successo");
                            setfeedbackType("alert-success");
                        }else{
                            setFeedback("Errore durante l'invio dei dati");
                            setfeedbackType("alert-danger");
                        }
                    }).catch((error) => {
                });
            }else{
                setFeedback("Compila la motorizzazione");
                setfeedbackType("alert-danger");
            }
        }else{
            setFeedback("Compila marca e modello");
            setfeedbackType("alert-danger");
        }
    }
    
    const onSelectedBrand = (event) => {

        const toPost={
            brand_id: event.target.value
        };

        setSelectedBrand(toPost.brand_id);

        axios.post(process.env.REACT_APP_API_HOST + "car/getbrandmodels", toPost, config)
        .then((response) => {
            setModels(response.data.items);
        }).catch((error) => {
        });
    }

    const onSelectedModel = (event) => {
        setSelectedModel(event.target.value);
    }

    const onSelectedEngine = (event) => {
        setSelectedEngine(event.target.value);
    }

    return(
        <div>
            {(cookie === undefined) ? <Header /> : ''}
            <div className="main-box">
                <div className = "container">
                    <div className = "row">
                        <div className = "col-lg-6 col-md-12 mr-auto ml-auto">
                            {isAdmin === 'loading' ? (
                                <Spinner className="ml-2 disc-name-color" animation="border" role="status">
                                    <span className="d-none">Loading...</span>
                                </Spinner>
                            ):(
                                !isAdmin ? window.location.replace('/') : (
                                    <>
                                    <a href="/admin"><i className="fa fa-arrow-left fa-lg"></i></a>
                                    <form onSubmit={onSubmit.bind(this)}>
                                        <h2 className="form-title mb-2">Aggiungi motorizzazione esistente</h2>
                                        <div className="text-muted form-title mb-4">Utilizza questa pagina per aggiungere una motorizzazione già esistente ad un modello di auto.</div>
                                        <div className = "form-group">
                                            <select className="form-control" onChange={onSelectedBrand.bind(this)} defaultValue={'select'}>
                                                <option value="select" disabled>Scegli la marca</option>
                                                {brands.map(brand => (
                                                    <option key={brand.id} value={brand.id}>{brand.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className = "form-group">
                                            <select className="form-control" onChange={onSelectedModel.bind(this)} defaultValue={'select'}>
                                                <option value="select" disabled>Scegli il modello</option>
                                                {models.map(model => (
                                                    <option key={model.id} value={model.id}>{model.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className = "form-group">
                                            <select className="form-control" value={selectedEngine} onChange={onSelectedEngine.bind(this)} defaultValue={'select'}>
                                                <option value="select" disabled>Scegli la motorizzazione</option>
                                                {engines.map(engine => (
                                                    <option key={engine.id} value={engine.id}>{engine.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <button 
                                            type="submit"
                                            className = "btn btn-primary">
                                            Aggiungi
                                        </button>
                                    </form>
                                    {feedback ? (
                                        <div className={"alert " + feedbackType}>
                                            {feedback}
                                        </div>
                                    ):('')}
                                    </>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {(cookie === undefined) ? <Footer /> : ''}
        </div>
    );
    
}

export default AddExistingEngines;
