import React, {useState} from "react";
import LoginService from "./loginService";
import CustomInput from "../../common/input/customInput";
import CryptoJS from 'crypto-js/aes';
import Cookies from 'universal-cookie';
import Spinner from 'react-bootstrap/Spinner';
import RaceLogo from '../../images/logo-gray-track.png';
import BgVideo from '../../videos/Bg-video-tuner.mp4';

function Login (props){

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [feedback, setFeedback] = useState("");
    const [isSubmitted, setSubmitted] = useState(false);
    const loginService = new LoginService();
    const cookies = new Cookies();
   
    const changeUsername = (event) => {
        setUsername(event.target.value.trim());
    }

    const changePassword = (event) => {
        setPassword(event.target.value.trim());
    }

    const onSubmit = (event) => {
        event.preventDefault();
    }

    const loginSuccess = (dataResult) => {
        //props.userToken(dataResult.token);
        
        var encrypted = CryptoJS.encrypt(dataResult.udata[0].id+"%"+dataResult.token+"%"+dataResult.udata[0].username, process.env.REACT_APP_CRYPT_KEY);
        let cookieDateExpire = new Date();
        let cookieDaysExpire=3;
        cookieDateExpire.setDate(cookieDateExpire.getDate() + cookieDaysExpire);
        cookies.set('data', encrypted.toString(), {path: "/", expires: cookieDateExpire});
        
        window.location.replace("/");
    }

    const loginError = (errorData) => {
        setSubmitted(false);
        setFeedback(errorData);
    }

    const login = (event) => {
        if(username!=='' && password!==''){
            setSubmitted(true);
            setFeedback('');
            loginService.login(username, password, loginSuccess.bind(this), loginError.bind(this));
        }else{
            setFeedback('Inserisci i campi obbligatori');
        }
    }
    
    return(
        <div className="main">
            {window.innerWidth < 768 ? '' : (<video id="backVideo" src={BgVideo} autoPlay loop muted />) }
            <div className="video-overlay"></div>
            <div className="main-box login-video">
                <div className = "container">
                    <div className = "row">
                        <div className = "accessForm col-md-8 col-lg-6 mr-auto ml-auto">
                            <img src={RaceLogo} className="col-md-12 mr-auto ml-auto mb-3" width={'400px'} alt="race-logo"></img>
                            <h1 className="title-login">Le elaborazioni a carte scoperte.</h1>
                            <form onSubmit={onSubmit.bind(this)}>
                                <div className = "form-group">
                                    <CustomInput 
                                        type="text"
                                        //label="username"
                                        placeholder="username"
                                        value = {username || ''}
                                        onChange = {changeUsername.bind(this)}
                                        />
                                </div>
                                <div className = "form-group">
                                    <CustomInput 
                                    type="password"
                                    //label="password"
                                    placeholder="password"
                                    value = {password}
                                    onChange = {changePassword.bind(this)}
                                    />
                                </div>
                                {!isSubmitted ? (
                                    <button 
                                        type="submit"
                                        className = "btn btn-primary"
                                        onClick={login.bind(this)}>
                                        Accedi
                                    </button>
                                ) : (
                                    feedback === '' ? (
                                    <Spinner className="disc-name-color" animation="border" role="status">
                                        <span className="d-none">Loading...</span>
                                    </Spinner>) : ('')
                                )}
                            </form>
                            {feedback ? (
                                <div className="alert alert-danger">
                                    {feedback}
                                </div>
                            ):('')}
                            <div className="row col-md-12 mt-2"><a href="/forgotPassword">Recupera password</a></div>
                            <div className="row col-md-12"><a href="/register">Non hai un account? Registrati</a></div>
                            <div className="row col-md-12"><a href="/contactus">Contattaci</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
