import React, {useState, useEffect} from "react";
import CustomInput from "../../common/input/customInput";
//import ImagesUpload from "../../common/input/imagesUpload";
import axios from "axios";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import Avatar from 'react-avatar';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

function UserDetails (props){

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [instagram, setInstagram] = useState("");
    const [instaFromDb, setInstaFromDb] = useState("");
    const [old_pw, setOldPw] = useState("");
    const [pw1, setPw1] = useState("");
    const [pw2, setPw2] = useState("");
    const [feedback, setFeedback] = useState("");
    const [feedbackType, setfeedbackType] = useState("");
    const [DBOldPw, setDBOldPw] = useState("");
    let errors= '';

    const cookies = new Cookies();
    const cookieString = cookies.get('data');
    let decrypted = CryptoJS.decrypt(cookieString, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8).split('%');
    //data[0]=uid, data[1]=token, data[2]=username
    let config = {
        headers: getHeadersAuthorization(cookieString)
    }
   
    const changeUsername = (event) => {
        if(event.target.value.length < 45){
            setUsername(event.target.value);
        }
    }

    const changeEmail = (event) => {
        if(event.target.value.length < 45){
            setEmail(event.target.value);
        }
    }

    const changeInstagram = (event) => {
        setInstagram(event.target.value);
    }

    const changeOldPw = (event) => {
        setOldPw(event.target.value);
    }

    const changePw1 = (event) => {
        setPw1(event.target.value);
    }

    const changePw2 = (event) => {
        setPw2(event.target.value);
    }

    /* const changeImages = (files) => {
        setImages(files);
    } */

    const validateEmail = (mail) => {
        const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(String(mail).toLowerCase());
    }

    const checkPasswords = () => {
        if(pw1!=='' && pw2!==''){
            //caso di pw non criptata a db (utente di test)
            if(old_pw === DBOldPw){
                errors = 'Impossibile modificare la password delle utenze di test. Se non sei utente di test contattaci a info@racemood.it';
                setfeedbackType('alert-danger');
                return false;
            }
            //controllo se la vecchia password è corretta con quella del db
            let decrypted_DBoldpw = CryptoJS.decrypt(DBOldPw, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
            if(old_pw === decrypted_DBoldpw){
                //Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
                //questa prende tutti i caratteri speciali, non solo quelli specificati: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
                const pwReg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
                if(pwReg.test(pw1)){
                    if(pw1 === pw2){
                        return true;
                    }else{
                        errors = 'Le due password non corrispondono';
                        setfeedbackType('alert-danger');
                        return false;
                    }
                }else{
                    errors = 'La password deve contenere almeno 8 caratteri, di cui almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale tra questi: #?!@$%^&*-';
                    setfeedbackType('alert-danger');
                    return false;
                }
            }else{
                errors = "La vecchia password è errata";
                setfeedbackType('alert-danger');
                return false;
            }
        }else{
            errors = "Per cambiare la password devi compilare tutti i campi nella sezione 'Password'";
            setfeedbackType('alert-danger');
            return false;
        }
    }

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_HOST + "user/getuser", {uid: decrypted[0]}, config)
            .then((response) => {
                setEmail(response.data.items[0].email);
                setDBOldPw(response.data.items[0].password);
                setInstagram(response.data.items[0].insta_name);
                setInstaFromDb(response.data.items[0].insta_name);
            }).catch((error) => {
                if (error.response.status === 401) {
                    //devo ri-instanziare Cookies perché ancora non esiste
                    cookies.remove('data', { path: '/' });
                    window.location.replace("/login");
                }
            });
    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();

        if(validateEmail(email)){
            var toPost = {
                uid: decrypted[0],
                pw: null,
                instagram: null
            };

            let atLeastOneMod = false;

            //se una delle 3 pw è valorizzata significa che vuole cambiare la pw
            if(old_pw!=='' || pw1!=='' || pw2!==''){
                if(checkPasswords()){
                    var encryptedPW = CryptoJS.encrypt(pw1, process.env.REACT_APP_CRYPT_KEY).toString();
                    toPost.pw = encryptedPW;
                }
                atLeastOneMod = true;
            }

            //se instagram è diverso da quello che arriva da DB allora lo vuole cambiare
            if(instagram !== instaFromDb){
                if(instagram.length > 3){
                    //se c'è la chiocciola, la levo
                    toPost.instagram = instagram.replace('@','');
                } else {
                    errors = 'Inserisci un nome Instagram corretto';
                }
                atLeastOneMod = true;
            }

            if(errors!==''){
                setfeedbackType('alert-danger');
                setFeedback(errors);
            } else if(atLeastOneMod) {
                axios.post(process.env.REACT_APP_API_HOST + "user/edit", toPost, config)
                .then((response) => {
                    //dare un feedback in verde sotto il tasto
                    if(response.data.response==='SUCCESS'){
                        setOldPw('');
                        setPw1('');
                        setPw2('');
                        setfeedbackType('alert-success');
                        setFeedback('Modifiche avvenute con successo');
                        setInstaFromDb(toPost.instagram);
                    }else{
                        setfeedbackType('alert-danger');
                        setFeedback(response.data.response);
                    }
                }).catch((error) => {});
            } else {
                setfeedbackType('alert-danger');
                setFeedback('Non ci sono nuove modifiche da salvare');
            }
        }else{
            setfeedbackType('alert-danger');
            setFeedback('Email non corretta');    
        }


        //var toPost = new FormData();        

        /* if(images){
            toPost.append('uid', uid);
            toPost.append('title', title);
            toPost.append('description', description);
            toPost.append('video', video);

            for (let fileList = 0; fileList < images.length; fileList++) {
                for(var k in images[fileList]){
                    toPost.append('image_'+k, images[fileList][k]);
                }
            }
            
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            axios.post("/elaborations/insert", toPost, config)
                .then((response) => {
                    window.location.replace("/");
                }).catch((error) => {
            });
        } */
        
    }
    
    return(
        <div className="main-box">
            <div className = "container">
                <div className = "row">
                    <div className = "col-lg-6 col-md-12 mr-auto ml-auto">
                        <form onSubmit={onSubmit.bind(this)}>
                            <h2 style={{textAlign:"center"}}>Area Utente</h2>
                            <div className = "d-flex justify-content-center">
                                <Avatar name={decrypted[2]} size="150" round="75px" />
                            </div>
                            <label>Username</label>
                            <div className = "form-group">
                                <CustomInput 
                                    type="text"
                                    name="username"
                                    value = {username || decrypted[2]}
                                    onChange = {changeUsername.bind(this)}
                                    disabled = 'disabled'
                                />
                            </div>
                            <label>Email</label>
                            <div className = "form-group">
                                <CustomInput 
                                    type="email"
                                    name="email"
                                    value = {email}
                                    onChange = {changeEmail.bind(this)}
                                    disabled
                                />
                            </div>
                            <label>Instagram (senza @)</label>
                            <div className = "form-group">
                                <CustomInput 
                                    type="text"
                                    name="instagram"
                                    placeholder="instagram"
                                    value = {instagram || ''}
                                    onChange = {changeInstagram.bind(this)}
                                />
                            </div>
                            <label>Password</label>
                            <div className = "form-group">
                                <CustomInput 
                                    type="password"
                                    name="old_pw"
                                    placeholder="vecchia password"
                                    value = {old_pw || ''}
                                    onChange = {changeOldPw.bind(this)}
                                />
                            </div>
                            <div className = "form-group">
                                <CustomInput 
                                    type="password"
                                    name="pw1"
                                    placeholder="nuova password"
                                    value = {pw1 || ''}
                                    onChange = {changePw1.bind(this)}
                                />
                            </div>
                            <div className = "form-group">
                                <CustomInput 
                                    type="password"
                                    name="pw2"
                                    placeholder="ripeti nuova password"
                                    value = {pw2 || ''}
                                    onChange = {changePw2.bind(this)}
                                />
                            </div>
                            {/* <div className="form-group">
                                <ImagesUpload onEdit={changeImages.bind(this)} />
                            </div> */}
                            <button 
                                type="submit"
                                className = "btn btn-primary">
                                Salva
                            </button>
                        </form>
                        {feedback ? (
                            <div className={"alert " + feedbackType}>
                                {feedback}
                            </div>
                        ):('')}
                    </div>
                </div>
            </div>
        </div>
    );
    
}
export default UserDetails;
