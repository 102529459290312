import React, {useState} from "react";
import {Button, Modal} from 'react-bootstrap';
import CustomInput from "../../common/input/customInput";
import Spinner from 'react-bootstrap/Spinner';
import Cookies from 'universal-cookie';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

function EditModal(props) {

    const id = props.id;
    const type = props.type;
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState(props.title);
    const [description, setDescription] = useState(props.description);
    const [charCounter, setCharCounter] = useState("0");
    const [isSubmitted, setSubmitted] = useState(false);
    const limitChars = 600;

    const cookies = new Cookies();
    const cookieString = cookies.get('data');

    const handleClose = () => setShow(false);
    const handleShow = () => {
      setShow(true);
      setCharCounter(description.length);
    }

    const onChangeTitle = (event) => {
      if(typeof event.target.value !== 'undefined' && event.target.value.length < 60){
        setTitle(event.target.value);
      }
    }

    const onChangeDescription = (event) => {
      var text_length = event.target.value.length;
      if(typeof event.target.value !== 'undefined' && text_length <= limitChars){
        setDescription(event.target.value);
        setCharCounter(text_length);
      }
  }

    const handleSubmit = async (event) => {
      event.preventDefault();
      setSubmitted(true);
      const toPost={
        id: id,
        title: title,
        description: description
      };
      const url = process.env.REACT_APP_API_HOST + (type === 'ELAB' ? 'elaborations' : 'discussions') + "/edit";
      let headers = getHeadersAuthorization(cookieString);
      headers['Content-Type'] = 'application/json';
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(toPost)
      }); 
      const data = await response.json();
      
      if (data.response === 'SUCCESS') {
        window.location.replace("/user/posts")
      }
    }

    return (
      <>
        <Button variant="primary" onClick={handleShow} className={props.customclass}>
          Modifica
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modifica {(type==='ELAB') ? 'Post':'Domanda'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className = "container">
              <div className = "row">
                <div className = "col-12">
                  <p>Titolo</p>
                  <div className = "form-group">
                    <CustomInput 
                      type="text"
                      name="title"
                      placeholder="Titolo"
                      value = {title || ''}
                      onChange = {onChangeTitle.bind(this)}
                    />
                  </div>
                  <p>Descrizione</p>
                  <div className = "form-group">
                    <textarea 
                      className="form-control"
                      name="description"
                      placeholder="Descrizione"
                      value = {description}
                      onChange = {onChangeDescription.bind(this)}
                      rows="5"
                    />
                    <div className="char-counter text-muted float-right">{charCounter}/{limitChars}</div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Annulla
            </Button>
            {!isSubmitted ? (
              <Button variant="primary" onClick={handleSubmit}>
                Salva
              </Button>
            ) : (
              <Spinner className="disc-name-color" animation="border" role="status">
                <span className="d-none">Loading...</span>
              </Spinner>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default EditModal;