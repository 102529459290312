import React from "react";
import CustomInput from "../../common/input/customInput";
import RaceLogo from '../../images/race-icon-cutted.png';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

export default class ChangePassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            payload: null,
            password: '',
            password2: '',
            pwchanged: null,
            errors: ''
        }
    }

    async componentDidMount(){
        const url = process.env.REACT_APP_API_HOST + "user/checkpwtoken/" + this.props.match.params.token;
        const response= await fetch(url, {
            method: 'POST',
            headers: getHeadersAuthorization(this.cookieString)
         });
        const data = await response.json();
        this.setState({payload: data.response, loading: false});
    }

    changeText(field, event){
        this.setState({[field]:event.target.value.trim()});
    }

    checkPasswords(){
        //Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (#?!@$%^&*-)
        const pwReg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if(pwReg.test(this.state.password)){
            this.setState({errors : ''});
            if(this.state.password === this.state.password2){
                return true;
            }else{
                this.setState({errors : 'Le due password non corrispondono'});
            }
        }else{
            this.setState({errors : 'La password deve contenere almeno 8 caratteri, di cui almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale tra questi: #?!@$%^&*-'});
        }
    }

    async onSubmit(event){
        event.preventDefault();
        if(this.checkPasswords()){
            this.setState({payload: null, loading: true});
            const toPost={
                newpw: this.state.password,
                token: this.props.match.params.token
            };
            const url ="/user/changepw/";
            const response= await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(toPost)
            });
            const data = await response.json();
            this.setState({pwchanged: data.response, loading: false});
        }
    }

    render(){
        return(<div className="main-box">
                    <div className = "container">
                        <div className = "row">
                            <div className="accessForm col-md-8 col-lg-6 mr-auto ml-auto">
                                <img src={RaceLogo} className="col-md-12 mr-auto ml-auto mb-4" width={'400px'} alt="race-logo"></img>
                                {(this.state.loading || !this.state.payload) && !this.state.pwchanged ? (
                                    <h4>Loading...</h4>
                                    ) : (
                                        <div>
                                            {this.state.payload==='NOT_FOUND' ? (
                                            <div style={{textAlign:"center"}}>
                                                Utente non trovato o link già utilizzato
                                                <div className="col-md-12 mt-4">
                                                    <a className="btn btn-primary mr-2" href="/login">Accedi</a>
                                                    <a className="btn btn-primary" href="/register">Registrati</a>
                                                </div>
                                            </div>
                                            ) : (
                                                <div>
                                                    <form onSubmit={this.onSubmit.bind(this)}>
                                                        <div className = "form-group">
                                                            <CustomInput 
                                                            type="password"
                                                            placeholder="nuova password"
                                                            value = {this.state.password}
                                                            onChange = {this.changeText.bind(this, "password")}
                                                            />
                                                        </div>
                                                        <div className = "form-group">
                                                            <CustomInput 
                                                            type="password"
                                                            placeholder="ripeti nuova password"
                                                            value = {this.state.password2}
                                                            onChange = {this.changeText.bind(this, "password2")}
                                                            />
                                                        </div>
                                                        <button 
                                                            type="submit"
                                                            className = "btn btn-primary mb-3">
                                                            Cambia Password
                                                        </button>
                                                        <div style={{color:'red'}}>
                                                            {this.state.errors}
                                                        </div>
                                                    </form>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                {this.state.pwchanged ? (this.state.pwchanged==='SUCCESS' ? (<div style={{color:'green'}}>Password aggiornata con successo</div>) : (<div style={{color:'red'}}>Errore durante l'aggiornamento della password</div>)) : (<div></div>)}
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}