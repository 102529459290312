import axios from "axios";
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';

export default class LoginService{

   login(username, password, onSuccess, onError){
      axios.post(process.env.REACT_APP_API_HOST + "login/getuser", {username: username})
      .then(function(result){
         var decryptedPW = CryptoJS.decrypt(result.data.udata[0].password, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
         //console.log(result.data);
         if(decryptedPW === password || result.data.udata[0].password === password){
            onSuccess(result.data);
         }else{
            onError("password errata");
         }
      }, function(error){
         onError(error.response.data);
      });
   }

}