import React, {useState, useEffect} from "react";
import axios from "axios";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import Header from "../mainLayout/header/header";
import Footer from "../mainLayout/footer/footer";
import Spinner from 'react-bootstrap/Spinner';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

function AdminPage (props){

    const cookies = new Cookies();
    let cookie = cookies.get('data');
    let decrypted = '';
    let config = null;
    let uid = null;

    const [isAdmin, setIsAdmin] = useState('loading');

    if(cookie !== undefined){
        config = {
            headers: getHeadersAuthorization(cookie)
        };

        decrypted = CryptoJS.decrypt(cookie, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
        //data[0]=uid, data[1]=token, data[2]=username
        uid = decrypted.split('%')[0];
    }

    useEffect(() => {
        const toPost={uid: uid};
        axios.post(process.env.REACT_APP_API_HOST + "user/isadmin", toPost, config)
        .then((response) => {
            setIsAdmin(response.data.items);
        });
    }, []);

    return(
        <div>
            {(cookie === undefined) ? <Header /> : ''}
            <div className="main-box">
                <div className = "container">
                    <div className = "row">
                        <div className = "col-lg-6 col-md-12 mr-auto ml-auto">
                        {isAdmin === 'loading' ? (
                                <Spinner className="ml-2 disc-name-color" animation="border" role="status">
                                    <span className="d-none">Loading...</span>
                                </Spinner>
                            ):(
                                !isAdmin ? window.location.replace('/') : (
                                    <>
                                    <h2 className="form-title mb-2">Pannello Admin</h2>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item"><a href="/addengine">Aggiungi nuovo motore</a></li>
                                        <li className="list-group-item"><a href="/addexistingengine">Collega motorizzazione ad un modello</a></li>
                                        <li className="list-group-item"><a href="/elaborationstages">Imposta step elaborazione per modello</a></li>
                                        <li className="list-group-item"><a href="/hpcalculator">Suggeritore elaborazione o Calcola cavalli</a></li>
                                        <li className="list-group-item"><a href="/hpcalculatorapprover">Approva elaborazione</a></li>
                                    </ul>
                                    </>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {(cookie === undefined) ? <Footer /> : ''}
        </div>
    );
    
}

export default AdminPage;
