import React from "react";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

export default class Comment extends React.Component{ 

    constructor(props) {
        super(props);

        this.cookies = new Cookies();
        this.cookieString = this.cookies.get('data');

        let decrypted = "";

        if(this.cookieString !== undefined){
            decrypted = CryptoJS.decrypt(this.cookieString, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
            //data[0]=uid, data[1]=token, data[2]=username
        }

        this.state = {
            uid: decrypted.split('%')[0],
            description: '',
            id_elab: this.props.id_elab,
            payload: null,
            loading: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount(){
        const url = process.env.REACT_APP_API_HOST + "comments/get/" + this.state.id_elab;
        const response= await fetch(url, {
            method: 'POST',
            headers: getHeadersAuthorization(this.cookieString)
         });
        const data = await response.json();
        this.setState({payload: data, loading: false});
        if(data.items.length > 3){
            var accordion_body = document.getElementsByClassName(this.state.id_elab +"_accordion_body")[0];
            accordion_body.classList.add("accordion_content-resize")
        }
    }

    handleChange(event) {
        if(event.target.value.length < 600){
            this.setState({description: event.target.value});
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        if(this.state.description!=='' && this.state.description.length>1){
            const toPost={
                uid: this.state.uid,
                description: this.state.description,
                id_elab: this.state.id_elab
            };
            const url = process.env.REACT_APP_API_HOST + "comment/insert/";
            let headers = getHeadersAuthorization(this.cookieString);
            headers['Content-Type'] = 'application/json';
            const response= await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(toPost)
            });
            const data = await response.json();
            var payloadArray=Object.values(this.state.payload.items);
            payloadArray.push(data)
            var payloadobj={items: payloadArray};
            this.setState({
                payload: payloadobj,
                description: ''
            });
            var accordion_body = document.getElementsByClassName(this.state.id_elab +"_accordion_body")[0];
            accordion_body.scrollTop = accordion_body.scrollHeight;
        }
    }

    render(){ 
        return( 
            <div className="accordion_content mt-4">
                {this.state.loading || !this.state.payload ? (
                    <div>loading...</div>
                    ) : (
                    <div className={this.state.id_elab +"_accordion_body custom_scroll"}>
                        <div>
                            {this.state.payload.items.map(item => (
                            <div key={item.id}>
                                <hr/>
                                <h6>{item.username}</h6>
                                {item.description}
                            </div>
                            ))}
                        </div>
                    </div>
                )}
                <hr/>
                <form onSubmit={this.handleSubmit}>
                    <input type="text" name="comment" className="form-control" value={this.state.description} onChange={this.handleChange} placeholder="Scrivi un commento..."/>
                </form>
            </div>
        ); 
    } 
}