import React, {useState, useEffect} from "react";
import axios from "axios";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import Header from "../mainLayout/header/header";
import Footer from "../mainLayout/footer/footer";
import "../../css/home/hpcalculator.css";
import Spinner from 'react-bootstrap/Spinner';
import NumberAnimated from './numberanimated';
import chipLogo from '../../images/category_icons/chip.png';
import intakeLogo from '../../images/category_icons/intake.png';
import coiloverLogo from '../../images/category_icons/coilover.png';
import exhaustLogo from '../../images/category_icons/exhaust.png';
import turboLogo from '../../images/category_icons/turbo.png';
import sparkLogo from '../../images/category_icons/sparkplug.png';
import utilityLogo from '../../images/category_icons/utility.png';
import intercoolerLogo from '../../images/category_icons/intercooler.png';
import brakeLogo from '../../images/category_icons/brake.png';
import pistonLogo from '../../images/category_icons/piston.png';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

function HpCalculatorApprover (props){

    let categories = [1,2,3,4,5,6,7,8,9,10];

    let categoriesLogos = {
        1: chipLogo,
        2: intakeLogo,
        3: coiloverLogo,
        4: exhaustLogo,
        5: turboLogo,
        6: sparkLogo,
        7: utilityLogo,
        8: intercoolerLogo,
        9: brakeLogo,
        10: pistonLogo,
    }

    const [modsCategories, setModsCategories] = useState({list: [],
        stg1: categories, 
        stg2: categories, 
        stg3: categories, 
        stg4: categories
    });
    const [carBuilds, setCarBuilds] = useState([]);
    const [isAdmin, setIsAdmin] = useState('loading');
    const [loading, setLoading] = useState(true);

    const cookies = new Cookies();
    let cookie = cookies.get('data');
    let decrypted = '';
    let uid = null;
    let config = null;

    if(cookie !== undefined){
        config = {
            headers: getHeadersAuthorization(cookie)
        };

        decrypted = CryptoJS.decrypt(cookie, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
        //data[0]=uid, data[1]=token, data[2]=username
        uid = decrypted.split('%')[0];
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric"}
        return new Date(dateString).toLocaleDateString(undefined, options)
      }

    useEffect(() => {
        const toPost={uid: uid};
        axios.post(process.env.REACT_APP_API_HOST + "user/isadmin", toPost, config)
        .then((response) => {
            setIsAdmin(response.data.items);
        });

        axios.post(process.env.REACT_APP_API_HOST + "car/getallmodscategories", [], config)
        .then((response) => {
            let mc = {list: response.data.items, 
                stg1: categories, 
                stg2: categories, 
                stg3: categories, 
                stg4: categories};

                axios.post(process.env.REACT_APP_API_HOST + "car/retrievenotapprovedcarbuilds", [], config)
                .then((response) => {
                    let modsLabeled = [];
                        response.data.labels.forEach((el) => {
                            modsLabeled[el.id]={name: el.name, cat_id: el.category_id};
                        });
        
                        let carModsArr = [];
                        response.data.items.forEach(item => {
                            //stage 1
                            let stg1_arr = [];
                            if(item.stg1_build){
                                let stg1 = item.stg1_build.split(',');
                                stg1.forEach((el) => {
                                    stg1_arr.push(modsLabeled[el]);
                                });
                            }
                            
        
                            //stage 2
                            let stg2_arr = [];
                            if(item.stg2_build){
                                let stg2 = item.stg2_build.split(',');
                                stg2.forEach((el) => {
                                    stg2_arr.push(modsLabeled[el]);
                                });
                            }
        
                            //stage 3
                            let stg3_arr = [];
                            if(item.stg3_build){
                                let stg3 = item.stg3_build.split(',');
                                stg3.forEach((el) => {
                                    stg3_arr.push(modsLabeled[el]);
                                });
                            }
        
                            //stage 4
                            let stg4_arr = [];
                            if(item.stg4_build){
                                let stg4 = item.stg4_build.split(',');
                                stg4.forEach((el) => {
                                    stg4_arr.push(modsLabeled[el]);
                                });
                            }
                            
                            carModsArr.push({
                                id: item.id,
                                stg1_build: stg1_arr, stg1_cv: item.stg1_cv,
                                stg2_build: stg2_arr, stg2_cv: item.stg2_cv,
                                stg3_build: stg3_arr, stg3_cv: item.stg3_cv,
                                stg4_build: stg4_arr, stg4_cv: item.stg4_cv,
                                username: item.username,
                                created: item.created
                            });
                        });
                        setCarBuilds(carModsArr);

                        if(carModsArr.length > 0){
                            let modCatList = mc.list;
                            let modsCategoriesAppo = [];
                            modsCategoriesAppo['list'] = modCatList;
        
                            carModsArr.forEach((carModArr) => {
                                let cat_stg1 = [];
                                carModArr.stg1_build.forEach((el)=>{
                                    if(!cat_stg1.includes(el.cat_id)){
                                        cat_stg1.push(el.cat_id);
                                    }
                                });
                
                                let cat_stg2 = [];
                                carModArr.stg2_build.forEach((el)=>{
                                    if(!cat_stg2.includes(el.cat_id)){
                                        cat_stg2.push(el.cat_id);
                                    }
                                });
                
                                let cat_stg3 = [];
                                carModArr.stg3_build.forEach((el)=>{
                                    if(!cat_stg3.includes(el.cat_id)){
                                        cat_stg3.push(el.cat_id);
                                    }
                                });
                
                                let cat_stg4 = [];
                                carModArr.stg4_build.forEach((el)=>{
                                    if(!cat_stg4.includes(el.cat_id)){
                                        cat_stg4.push(el.cat_id);
                                    }
                                });
        
                                modsCategoriesAppo["id_"+carModArr.id] = {
                                    stg1: cat_stg1,
                                    stg2: cat_stg2,
                                    stg3: cat_stg3,
                                    stg4: cat_stg4
                                };
                            });
            
                            setModsCategories(modsCategoriesAppo);
                        }
                    setLoading(false);
                }).catch((error) => {});
        }).catch((error) => {});
    }, []);

    const showPotential = (numberToDisplay) => {
        return (
            <div className="potential-container">
                <div className="cv-title">Potenziale</div>
                <NumberAnimated className="cv-display" n={parseInt(numberToDisplay)}/>
            </div>
        );
    }

    const approveBuild = (id) => {
        const toPost={id: id};
        axios.post(process.env.REACT_APP_API_HOST + "car/approvebuild", toPost, config)
        .then((response) => {
            if(response){
                window.location.reload();
            }
        });
    }

    const deleteBuild = (id) => {
        const toPost={id: id};
        axios.post(process.env.REACT_APP_API_HOST + "car/deletebuild", toPost, config)
        .then((response) => {
            if(response){
                window.location.reload();
            }
        });
    }

    return(
        <div>
            {(cookie === undefined) ? <Header /> : ''}
            <div className="main-box">
                <div className = "container">
                    <div className = "row">
                        <div className = "col-lg-6 col-md-12 mr-auto ml-auto">
                            {isAdmin === 'loading' ? (
                                <Spinner className="ml-2 disc-name-color" animation="border" role="status">
                                    <span className="d-none">Loading...</span>
                                </Spinner>
                            ):(
                                !isAdmin ? window.location.replace('/') : (
                                <>
                                <a href="/admin"><i className="fa fa-arrow-left fa-lg"></i></a>
                                <h2 className="form-title mb-2">Approvatore elaborazioni</h2>
                                <div className="text-muted form-title mb-4">Questa pagina serve per approvare le elaborazioni</div>

                                {loading ? (
                                    <div className="item-centered">
                                        <Spinner className="disc-name-color" animation="border" role="status">
                                            <span className="d-none">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    carBuilds.length > 0 ? (
                                        carBuilds.map((carBuild, i) => (
                                            <div key={carBuild.id}>
                                                <div className="separator" style={{width: '100%', fontSize: '30px'}}>{i+1}</div>
                                                <div className="text-muted">
                                                    username: <b>{carBuild.username}</b><br/>
                                                    created: <b>{formatDate(carBuild.created)}</b>
                                                </div>
                                                <nav className="mt-4">
                                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                        {carBuild.stg1_build.length > 0 ? (<button className="nav-link active" id={"nav-stg1-tab-"+carBuild.id} data-toggle="tab" data-target={"#nav-stg1-"+carBuild.id} type="button" role="tab" aria-controls={"nav-stg1-"+carBuild.id} aria-selected="true">Stage 1</button>):('')}
                                                        {carBuild.stg2_build.length > 0 ? (<button className="nav-link" id={"nav-stg2-tab-"+carBuild.id} data-toggle="tab" data-target={"#nav-stg2-"+carBuild.id} type="button" role="tab" aria-controls={"nav-stg2-"+carBuild.id} aria-selected="false">Stage 2</button>):('')}
                                                        {carBuild.stg3_build.length > 0 ? (<button className="nav-link" id={"nav-stg3-tab-"+carBuild.id} data-toggle="tab" data-target={"#nav-stg3-"+carBuild.id} type="button" role="tab" aria-controls={"nav-stg3-"+carBuild.id} aria-selected="false">Stage 3</button>):('')}
                                                        {carBuild.stg4_build.length > 0 ? (<button className="nav-link" id={"nav-stg4-tab-"+carBuild.id} data-toggle="tab" data-target={"#nav-stg4-"+carBuild.id} type="button" role="tab" aria-controls={"nav-stg4-"+carBuild.id} aria-selected="false">Stage 4</button>):('')}
                                                    </div>
                                                </nav>
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div className="tab-pane fade show active" id={"nav-stg1-"+carBuild.id} role="tabpanel" aria-labelledby={"nav-stg1-tab"+carBuild.id}>
                                                        {showPotential(carBuild.stg1_cv)}
                                                        <div className="mods-container">
                                                            {modsCategories.list.map(modCat => (
                                                                modsCategories["id_"+carBuild.id].stg1.includes(modCat.id) ? (
                                                                    <div key={modCat.id} className="category-mod-item">
                                                                        <div className="mod-category-title">
                                                                            <img className="cat-logos-size" src={categoriesLogos[modCat.id]} alt={modCat.name}/>
                                                                            <div className="mod-category-lbl">{modCat.name}</div>
                                                                        </div>
                                                                        <hr style={{'marginTop':'3px'}}/>
                                                                        <ul className="ul-mods">
                                                                            {carBuild.stg1_build.map(build => (
                                                                                build.cat_id === modCat.id ? (
                                                                                    <li className="mod-item">
                                                                                        {build.name}
                                                                                    </li>
                                                                                ):('')
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                ) : ('')
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id={"nav-stg2-"+carBuild.id} role="tabpanel" aria-labelledby={"nav-stg2-tab-"+carBuild.id}>
                                                        {showPotential(carBuild.stg2_cv)}
                                                        <div className="mods-container">
                                                            {modsCategories.list.map(modCat => (
                                                                modsCategories["id_"+carBuild.id].stg2.includes(modCat.id) ? (
                                                                    <div key={modCat.id} className="category-mod-item">
                                                                        <div className="mod-category-title">
                                                                            <img className="cat-logos-size" src={categoriesLogos[modCat.id]} alt={modCat.name}/>
                                                                            <div className="mod-category-lbl">{modCat.name}</div>
                                                                        </div>
                                                                        <hr style={{'marginTop':'3px'}}/>
                                                                        <ul className="ul-mods">
                                                                            {carBuild.stg2_build.map(build => (
                                                                                build.cat_id === modCat.id ? (
                                                                                    <li className="mod-item">
                                                                                        {build.name}
                                                                                    </li>
                                                                                ):('')
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                ) : ('')
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id={"nav-stg3-"+carBuild.id} role="tabpanel" aria-labelledby={"nav-stg3-tab-"+carBuild.id}>
                                                        {showPotential(carBuild.stg3_cv)}
                                                        <div className="mods-container">
                                                            {modsCategories.list.map(modCat => (
                                                                modsCategories["id_"+carBuild.id].stg3.includes(modCat.id) ? (
                                                                    <div key={modCat.id} className="category-mod-item">
                                                                        <div className="mod-category-title">
                                                                            <img className="cat-logos-size" src={categoriesLogos[modCat.id]} alt={modCat.name}/>
                                                                            <div className="mod-category-lbl">{modCat.name}</div>
                                                                        </div>
                                                                        <hr style={{'marginTop':'3px'}}/>
                                                                        <ul className="ul-mods">
                                                                            {carBuild.stg3_build.map(build => (
                                                                                build.cat_id === modCat.id ? (
                                                                                    <li className="mod-item">
                                                                                        {build.name}
                                                                                    </li>
                                                                                ):('')
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                ) : ('')
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id={"nav-stg4-"+carBuild.id} role="tabpanel" aria-labelledby={"nav-stg4-tab-"+carBuild.id}>
                                                        {showPotential(carBuild.stg4_cv)}
                                                        <div className="mods-container">
                                                            {modsCategories.list.map(modCat => (
                                                                modsCategories["id_"+carBuild.id].stg4.includes(modCat.id) ? (
                                                                    <div key={modCat.id} className="category-mod-item">
                                                                        <div className="mod-category-title">
                                                                            <img className="cat-logos-size" src={categoriesLogos[modCat.id]} alt={modCat.name}/>
                                                                            <div className="mod-category-lbl">{modCat.name}</div>
                                                                        </div>
                                                                        <hr style={{'marginTop':'3px'}}/>
                                                                        <ul className="ul-mods">
                                                                            {carBuild.stg4_build.map(build => (
                                                                                build.cat_id === modCat.id ? (
                                                                                    <li className="mod-item">
                                                                                        {build.name}
                                                                                    </li>
                                                                                ):('')
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                ) : ('')
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="approver-actions">
                                                    <button className="btn btn-outline-success" onClick={approveBuild.bind(this, carBuild.id)}>Approva</button>
                                                    <button className="btn btn-outline-danger" onClick={deleteBuild.bind(this, carBuild.id)}>Elimina</button>
                                                </div>
                                            </div>
                                        ))
                                    ):('Non ci sono elaborazioni da approvare')
                                )}
                                </>)
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {(cookie === undefined) ? <Footer /> : ''}
        </div>
    );
    
}

export default HpCalculatorApprover;
