import React, {useState} from "react";
import {Button, Modal} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Cookies from 'universal-cookie';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

function DeleteModal(props) {

    const id = props.id;
    const title = props.title;
    const type = props.type;
    const [show, setShow] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);

    const cookies = new Cookies();
    const cookieString = cookies.get('data');
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = async (event) => {
      event.preventDefault();
      setSubmitted(true);
      
      const toPost={
        id: id
      };

      const url = process.env.REACT_APP_API_HOST + (type === 'ELAB' ? 'elaborations' : 'discussions') + "/delete";
      let headers = getHeadersAuthorization(cookieString);
      headers['Content-Type'] = 'application/json';
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(toPost)
      }); 
      const data = await response.json();
      
      if (data.response === 'SUCCESS') {
        window.location.replace("/user/posts")
      }
    }

    return (
      <>
        <Button variant="danger" onClick={handleShow}>
          Elimina
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Elimina {(type==='ELAB') ? 'Post':'Domanda'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className = "container">
              <div className = "row">
                <div className = "col-12">
                  <p>Sei sicuro di voler eliminare <b>{title}</b>?</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Annulla
            </Button>
            {!isSubmitted ? (
              <Button variant="danger" onClick={handleSubmit}>
                Elimina
              </Button>
            ) : (
              <Spinner className="disc-name-color" animation="border" role="status">
                <span className="d-none">Loading...</span>
              </Spinner>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default DeleteModal;