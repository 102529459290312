import React from "react";
import Spinner from 'react-bootstrap/Spinner';
import RaceLogo from '../../images/race-icon-cutted.png';
import Cookies from 'universal-cookie';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

export default class UserValidate extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            payload: null
        }

        this.cookies = new Cookies();
        this.cookieString = this.cookies.get('data');
    }

    async componentDidMount(){
        const url = process.env.REACT_APP_API_HOST + "user/validate/" + this.props.match.params.token;
        let headers = getHeadersAuthorization(this.cookieString);
        headers['Content-Type'] = 'application/json';
        const response= await fetch(url, {
            method: 'POST',
            headers: headers
        });

        const data = await response.json();
        let payload = '';
        if(data.response === 'USR_NOT_FOUND'){
            payload = "Utente non trovato!";
        }else{
            payload = "Grazie per aver verificato la tua mail. Ora puoi utilizzare il tuo account.";
        }
        this.setState({payload: payload, loading: false});
    }

    render(){
        return(<div className="accessForm" style={{textAlign:"center"}}>
                    <img src={RaceLogo} className="col-md-3 mr-auto ml-auto mb-4" width={'400px'} alt="race-logo"></img>
                    {this.state.loading || !this.state.payload ? (
                        <div className="item-centered">
                            <Spinner className="disc-name-color" animation="border" role="status">
                                <span className="d-none">Loading...</span>
                            </Spinner>
                        </div>
                        ) : (
                            <div>
                                <h4>{this.state.payload}</h4>
                                <a className="btn btn-primary mt-4" href="/login">Accedi</a>
                            </div>
                    )}
                </div>
        );
    }
}