import React, {useState, useEffect} from "react";
import axios from "axios";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import Header from "../mainLayout/header/header";
import Footer from "../mainLayout/footer/footer";
import "../../css/home/hpcalculator.css";
import Spinner from 'react-bootstrap/Spinner';
import NumberAnimated from './numberanimated';
import chipLogo from '../../images/category_icons/chip.png';
import intakeLogo from '../../images/category_icons/intake.png';
import coiloverLogo from '../../images/category_icons/coilover.png';
import exhaustLogo from '../../images/category_icons/exhaust.png';
import turboLogo from '../../images/category_icons/turbo.png';
import sparkLogo from '../../images/category_icons/sparkplug.png';
import utilityLogo from '../../images/category_icons/utility.png';
import intercoolerLogo from '../../images/category_icons/intercooler.png';
import brakeLogo from '../../images/category_icons/brake.png';
import pistonLogo from '../../images/category_icons/piston.png';
import { Tooltip } from 'react-tooltip';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

function HpCalculator (props){

    let categories = [1,2,3,4,5,6,7,8,9,10];

    let categoriesLogos = {
        1: chipLogo,
        2: intakeLogo,
        3: coiloverLogo,
        4: exhaustLogo,
        5: turboLogo,
        6: sparkLogo,
        7: utilityLogo,
        8: intercoolerLogo,
        9: brakeLogo,
        10: pistonLogo,
    }

    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [engines, setEngines] = useState([]);
    const [selectedModel, setSelectedModel] = useState(0);
    const [selectedEngine, setSelectedEngine] = useState(0);
    const [selectedStage, setSelectedStage] = useState('');
    const [panelStageClass, setPanelStageClass] = useState({
        stg1: {tab: 'active', body: 'show active'},
        stg2: {tab: '', body: ''},
        stg3: {tab: '', body: ''},
        stg4: {tab: '', body: ''},
    });
    const [modsCategories, setModsCategories] = useState({list: [],
        stg1: categories, 
        stg2: categories, 
        stg3: categories, 
        stg4: categories
    });
    const [carBuilds, setCarBuilds] = useState([]);
    const [feedback, setFeedback] = useState('');
    const [feedbackType, setfeedbackType] = useState('');
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState(null);

    const cookies = new Cookies();
    let cookie = cookies.get('data');
    let decrypted = '';
    let uid = null;
    let conf = null;

    useEffect(() => {
        if(cookie !== undefined){
            conf = {
                headers: getHeadersAuthorization(cookie)
            };
            setConfig(conf);

            decrypted = CryptoJS.decrypt(cookie, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
            //data[0]=uid, data[1]=token, data[2]=username
            uid = decrypted.split('%')[0];

            axios.post(process.env.REACT_APP_API_HOST + "car/getallbrands", [], conf)
            .then((response) => {
                setBrands(response.data.items);
            }).catch((error) => {
                /* if (error.response.status === 401) {
                    //devo ri-instanziare Cookies perché ancora non esiste
                    cookies.remove('data', { path: '/' });
                    window.location.replace("/login");
                } */
            });
        } else {
            //se non è loggato, recupero il token generico da db e lo passo (url camuffata per non far capire dall'esterno)
            axios.post(process.env.REACT_APP_API_HOST + "car/getcarsvin", [], conf)
            .then((response) => {

                //il token è in mezzo ai '%'
                let token = response.data.vin.split('%')[1];
                
                //manca di fare la logica su index.js che se c'è il % è loggato senno no e
                //confronta il token con quello con uid null
                var encrypted = CryptoJS.encrypt(token, process.env.REACT_APP_CRYPT_KEY).toString();

                conf = {
                    headers: getHeadersAuthorization(encrypted)
                };
                setConfig(conf);

                axios.post(process.env.REACT_APP_API_HOST + "car/getallbrands", [], conf)
                .then((response) => {
                    setBrands(response.data.items);
                }).catch((error) => {
                    /* if (error.response.status === 401) {
                        //devo ri-instanziare Cookies perché ancora non esiste
                        cookies.remove('data', { path: '/' });
                        window.location.replace("/login");
                    } */
                });
            }).catch((error) => {});       
        }
    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();
        const toPost={
            model_id: selectedModel,
            engine_id: selectedEngine
        };

        if(selectedModel !== 0 && selectedEngine !==0){
            setLoading(true);

            axios.post(process.env.REACT_APP_API_HOST + "car/retrievecarbuilds", toPost, config)
            .then((response) => {
                let modsLabeled = [];
                response.data.labels.forEach((el) => {
                    modsLabeled[el.id]={name: el.name, cat_id: el.category_id};
                });

                let carModsArr = [];
                response.data.items.forEach(item => {
                    //stage 1
                    let stg1_arr = [];
                    if(item.stg1_build){
                        let stg1 = item.stg1_build.split(',');
                        stg1.forEach((el) => {
                            stg1_arr.push(modsLabeled[el]);
                        });
                    }
                    
                    //stage 2
                    let stg2_arr = [];
                    if(item.stg2_build){
                        let stg2 = item.stg2_build.split(',');
                        stg2.forEach((el) => {
                            stg2_arr.push(modsLabeled[el]);
                        });
                    }

                    //stage 3
                    let stg3_arr = [];
                    if(item.stg3_build){
                        let stg3 = item.stg3_build.split(',');
                        stg3.forEach((el) => {
                            stg3_arr.push(modsLabeled[el]);
                        });
                    }

                    //stage 4
                    let stg4_arr = [];
                    if(item.stg4_build){
                        let stg4 = item.stg4_build.split(',');
                        stg4.forEach((el) => {
                            stg4_arr.push(modsLabeled[el]);
                        });
                    }
                    
                    carModsArr.push({
                        id: item.id,
                        stg1_build: stg1_arr, stg1_cv: item.stg1_cv,
                        stg2_build: stg2_arr, stg2_cv: item.stg2_cv,
                        stg3_build: stg3_arr, stg3_cv: item.stg3_cv,
                        stg4_build: stg4_arr, stg4_cv: item.stg4_cv,
                        insta_name: item.insta_name
                    });
                });
                setCarBuilds(carModsArr);

                if(carModsArr.length > 0){

                    axios.post(process.env.REACT_APP_API_HOST + "car/getallmodscategories", [], config)
                    .then((respCategories) => {
                        let mc = {list: respCategories.data.items, 
                            stg1: categories, 
                            stg2: categories, 
                            stg3: categories, 
                            stg4: categories};

                        let modCatList = mc.list;
                        let modsCategoriesAppo = [];
                        modsCategoriesAppo['list'] = modCatList;

                        carModsArr.forEach((carModArr) => {
                            let cat_stg1 = [];
                            carModArr.stg1_build.forEach((el)=>{
                                if(!cat_stg1.includes(el.cat_id)){
                                    cat_stg1.push(el.cat_id);
                                }
                            });
            
                            let cat_stg2 = [];
                            carModArr.stg2_build.forEach((el)=>{
                                if(!cat_stg2.includes(el.cat_id)){
                                    cat_stg2.push(el.cat_id);
                                }
                            });
            
                            let cat_stg3 = [];
                            carModArr.stg3_build.forEach((el)=>{
                                if(!cat_stg3.includes(el.cat_id)){
                                    cat_stg3.push(el.cat_id);
                                }
                            });
            
                            let cat_stg4 = [];
                            carModArr.stg4_build.forEach((el)=>{
                                if(!cat_stg4.includes(el.cat_id)){
                                    cat_stg4.push(el.cat_id);
                                }
                            });

                            modsCategoriesAppo["id_"+carModArr.id] = {
                                stg1: cat_stg1,
                                stg2: cat_stg2,
                                stg3: cat_stg3,
                                stg4: cat_stg4
                            };
                        });
        
                        setModsCategories(modsCategoriesAppo);
                    }).catch((error) => {});
                }

                //se non torna nulla mettere feedback bello
                if(carModsArr.length <= 0){
                    setfeedbackType("alert-danger");
                    setFeedback("elab-not-found");
                } else {
                    setfeedbackType("");
                    setFeedback("");
                }

                //mostro per primo lo stage scelto
                let selStage = selectedStage==='' || selectedStage==='select' ? 'stg1':selectedStage;

                //azzero tab da mostrare
                let panelClass = {
                    stg1: {tab: '', body: ''},
                    stg2: {tab: '', body: ''},
                    stg3: {tab: '', body: ''},
                    stg4: {tab: '', body: ''}
                };
                //imposto tab da mostrare in base alla scelta
                panelClass[selStage] = {tab: 'active', body: 'show active'};
                setPanelStageClass(panelClass);

                setLoading(false);
            }).catch((error) => {});
        }else{
            setFeedback("Compila marca, modello e motorizzazione");
            setfeedbackType("alert-danger");
        }

        
    }
    
    const onSelectedBrand = (event) => {

        const toPost={
            brand_id: event.target.value
        };

        axios.post(process.env.REACT_APP_API_HOST + "car/getbrandmodels", toPost, config)
        .then((response) => {
            setModels(response.data.items);
        }).catch((error) => {
            /* if (error.response.status === 401) {
                //devo ri-instanziare Cookies perché ancora non esiste
                cookies.remove('data', { path: '/' });
                window.location.replace("/login");
            } */
        });

        //se cambia marca resetto le options delle select successive
        document.getElementById("model_select").value = 'select';
        document.getElementById("engine_select").value = 'select';
    }

    const onSelectedModel = (event) => {

        const toPost={
            model_id: event.target.value
        };
        setSelectedModel(toPost.model_id);
        axios.post(process.env.REACT_APP_API_HOST + "car/getmodelengines", toPost, config)
        .then((response) => {
            setEngines(response.data.items);
        }).catch((error) => {
            /* if (error.response.status === 401) {
                //devo ri-instanziare Cookies perché ancora non esiste
                cookies.remove('data', { path: '/' });
                window.location.replace("/login");
            } */
        });

        //se cambia modello resetto le options della select successiva
        document.getElementById("engine_select").value = 'select';
    }

    const onSelectedEngine = (event) => {
        setSelectedEngine(event.target.value);
    }

    const onSelectedStage = (event) => {
        setSelectedStage(event.target.value);
    }

    const showPotential = (numberToDisplay) => {
        return (
            <div className="potential-container">
                <div className="cv-title">
                    Potenziale
                    <i className="fas fa-info-circle info-cv-icon" data-tooltip-id="hp-explanation" 
                        data-tooltip-html="
                        <div>La potenza finale può variare in base a diversi fattori:</div>
                            <ul>
                                <li>qualità dei componenti utilizzati</li>
                                <li>stato di salute del motore</li>
                                <li>temperatura esterna</li>
                                <li>qualità del carburante utilizzato</li>
                            </ul>" 
                        data-tooltip-place="top">
                    </i>
                </div>
                <NumberAnimated className="cv-display" n={parseInt(numberToDisplay)}/>
            </div>
        );
    }

    return(
        <div>
            {(cookie === undefined) ? <Header /> : ''}
            <div className="semicerchio"></div>
            <div className="main-box-for-semicerchio">
                <div className = "container">
                    <div className = "row">
                        <div className = "col-lg-6 col-md-12 mr-auto ml-auto">
                            {/* {isAdmin === 'loading' ? (
                                <Spinner className="ml-2 disc-name-color" animation="border" role="status">
                                    <span className="d-none">Loading...</span>
                                </Spinner>
                            ):(
                                !isAdmin ? window.location.replace('/') : ( 
                                <>*/}
                                <form onSubmit={onSubmit.bind(this)}>
                                    <h2 className="form-title mb-2">Scopri il Potenziale della Tua Auto con il Calcolatore Cavalli</h2>
                                    <div className="text-muted form-title mb-4">Scegli lo step di preparazione che vuoi raggiungere e calcola in anticipo la potenza che avrà la tua auto!</div>
                                    <div className = "form-group">
                                        <select className="form-control" id="" onChange={onSelectedBrand.bind(this)} defaultValue={'select'}>
                                            <option value="select" disabled>Scegli la marca</option>
                                            {brands.map(brand => (
                                                <option key={brand.id} value={brand.id}>{brand.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className = "form-group">
                                        <select className="form-control" id="model_select" onChange={onSelectedModel.bind(this)} defaultValue={'select'}>
                                            <option value="select" disabled>Scegli il modello</option>
                                            {models.map(model => (
                                                <option key={model.id} value={model.id}>{model.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className = "form-group">
                                        <select className="form-control" id="engine_select" onChange={onSelectedEngine.bind(this)} defaultValue={'select'}>
                                            <option value="select" disabled>Scegli la motorizzazione</option>
                                            {engines.map(engine => (
                                                <option key={engine.id} value={engine.id}>{engine.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className = "form-group">
                                        <select className="form-control" id="stage_select" onChange={onSelectedStage.bind(this)} defaultValue={'select'}>
                                            <option value="select">Stage preparazione (opzionale)</option>
                                            <option value="stg1">Stage 1</option>
                                            <option value="stg2">Stage 2</option>
                                            <option value="stg3">Stage 3</option>
                                            <option value="stg4">Stage 4</option>
                                        </select>
                                    </div>
                                    <button 
                                        type="submit"
                                        className = "btn btn-primary">
                                        Calcola
                                    </button>
                                </form>

                                {feedback ? (
                                    <div className={"alert " + feedbackType}>
                                        {feedback === 'elab-not-found' ? (
                                            <>
                                            Nessuna elaborazione trovata per questo modello.<br/>
                                            Se vuoi suggerire tu un'elaborazione dettagliata 
                                            {!uid ? (<><a href="/register">registrati</a> e poi</>):('')}
                                             <a href="/elaborationstages"> clicca qui</a>
                                            </>
                                        ) : (feedback)}
                                    </div>
                                ):('')}

                                {loading ? (
                                    <div className="item-centered">
                                        <Spinner className="disc-name-color" animation="border" role="status">
                                            <span className="d-none">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    carBuilds.length > 0 ? (
                                        carBuilds.map((carBuild, i) => (
                                            <div className="elab-container">
                                                <div className="supplier-container">
                                                    <div className="supplier-name">
                                                        <i className="fa fa-instagram"></i>
                                                        {carBuild.insta_name ? (<a href={"https://www.instagram.com/"+carBuild.insta_name} target="_blank">{carBuild.insta_name}</a>):('')}
                                                    </div>
                                                </div>
                                                <nav className="mt-4">
                                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                        {carBuild.stg1_build.length > 0 ? (<button className={"nav-link "+panelStageClass.stg1.tab} id={"nav-stg1-tab-"+carBuild.id} data-toggle="tab" data-target={"#nav-stg1-"+carBuild.id} type="button" role="tab" aria-controls={"nav-stg1-"+carBuild.id} aria-selected="true">Stage 1</button>):('')}
                                                        {carBuild.stg2_build.length > 0 ? (<button className={"nav-link "+panelStageClass.stg2.tab} id={"nav-stg2-tab-"+carBuild.id} data-toggle="tab" data-target={"#nav-stg2-"+carBuild.id} type="button" role="tab" aria-controls={"nav-stg2-"+carBuild.id} aria-selected="false">Stage 2</button>):('')}
                                                        {carBuild.stg3_build.length > 0 ? (<button className={"nav-link "+panelStageClass.stg3.tab} id={"nav-stg3-tab-"+carBuild.id} data-toggle="tab" data-target={"#nav-stg3-"+carBuild.id} type="button" role="tab" aria-controls={"nav-stg3-"+carBuild.id} aria-selected="false">Stage 3</button>):('')}
                                                        {carBuild.stg4_build.length > 0 ? (<button className={"nav-link "+panelStageClass.stg4.tab} id={"nav-stg4-tab-"+carBuild.id} data-toggle="tab" data-target={"#nav-stg4-"+carBuild.id} type="button" role="tab" aria-controls={"nav-stg4-"+carBuild.id} aria-selected="false">Stage 4</button>):('')}
                                                    </div>
                                                </nav>
                                                <Tooltip id="hp-explanation"/>
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div className={"tab-pane fade "+panelStageClass.stg1.body} id={"nav-stg1-"+carBuild.id} role="tabpanel" aria-labelledby={"nav-stg1-tab"+carBuild.id}>
                                                        {showPotential(carBuild.stg1_cv)}
                                                        <div className="mods-container">
                                                            {modsCategories["id_"+carBuild.id] !== undefined ? (
                                                                modsCategories.list.map(modCat => (
                                                                    modsCategories["id_"+carBuild.id].stg1.includes(modCat.id) ? (
                                                                        <div key={modCat.id} className="category-mod-item">
                                                                            <div className="mod-category-title">
                                                                                <img className="cat-logos-size" src={categoriesLogos[modCat.id]} alt={modCat.name}/>
                                                                                <div className="mod-category-lbl">{modCat.name}</div>
                                                                            </div>
                                                                            <hr style={{'marginTop':'3px'}}/>
                                                                            <ul className="ul-mods">
                                                                                {carBuild.stg1_build.map(build => (
                                                                                    build.cat_id === modCat.id ? (
                                                                                        <li className="mod-item">
                                                                                            {build.name}
                                                                                        </li>
                                                                                    ):('')
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    ) : ('')
                                                                ))
                                                            ):('')}
                                                        </div>
                                                    </div>
                                                    <div className={"tab-pane fade "+panelStageClass.stg2.body} id={"nav-stg2-"+carBuild.id} role="tabpanel" aria-labelledby={"nav-stg2-tab-"+carBuild.id}>
                                                        {showPotential(carBuild.stg2_cv)}
                                                        <div className="mods-container">
                                                            {modsCategories["id_"+carBuild.id] !== undefined ? (
                                                                modsCategories.list.map(modCat => (
                                                                    modsCategories["id_"+carBuild.id].stg2.includes(modCat.id) ? (
                                                                        <div key={modCat.id} className="category-mod-item">
                                                                            <div className="mod-category-title">
                                                                                <img className="cat-logos-size" src={categoriesLogos[modCat.id]} alt={modCat.name}/>
                                                                                <div className="mod-category-lbl">{modCat.name}</div>
                                                                            </div>
                                                                            <hr style={{'marginTop':'3px'}}/>
                                                                            <ul className="ul-mods">
                                                                                {carBuild.stg2_build.map(build => (
                                                                                    build.cat_id === modCat.id ? (
                                                                                        <li className="mod-item">
                                                                                            {build.name}
                                                                                        </li>
                                                                                    ):('')
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    ) : ('')
                                                                ))
                                                            ):('')}
                                                        </div>
                                                    </div>
                                                    <div className={"tab-pane fade "+panelStageClass.stg3.body} id={"nav-stg3-"+carBuild.id} role="tabpanel" aria-labelledby={"nav-stg3-tab-"+carBuild.id}>
                                                        {showPotential(carBuild.stg3_cv)}
                                                        <div className="mods-container">
                                                            {modsCategories["id_"+carBuild.id] !== undefined ? (
                                                                modsCategories.list.map(modCat => (
                                                                    modsCategories["id_"+carBuild.id].stg3.includes(modCat.id) ? (
                                                                        <div key={modCat.id} className="category-mod-item">
                                                                            <div className="mod-category-title">
                                                                                <img className="cat-logos-size" src={categoriesLogos[modCat.id]} alt={modCat.name}/>
                                                                                <div className="mod-category-lbl">{modCat.name}</div>
                                                                            </div>
                                                                            <hr style={{'marginTop':'3px'}}/>
                                                                            <ul className="ul-mods">
                                                                                {carBuild.stg3_build.map(build => (
                                                                                    build.cat_id === modCat.id ? (
                                                                                        <li className="mod-item">
                                                                                            {build.name}
                                                                                        </li>
                                                                                    ):('')
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    ) : ('')
                                                                ))
                                                            ):('')}
                                                        </div>
                                                    </div>
                                                    <div className={"tab-pane fade "+panelStageClass.stg4.body} id={"nav-stg4-"+carBuild.id} role="tabpanel" aria-labelledby={"nav-stg4-tab-"+carBuild.id}>
                                                        {showPotential(carBuild.stg4_cv)}
                                                        <div className="mods-container">
                                                            {modsCategories["id_"+carBuild.id] !== undefined ? (
                                                                modsCategories.list.map(modCat => (
                                                                    modsCategories["id_"+carBuild.id].stg4.includes(modCat.id) ? (
                                                                        <div key={modCat.id} className="category-mod-item">
                                                                            <div className="mod-category-title">
                                                                                <img className="cat-logos-size" src={categoriesLogos[modCat.id]} alt={modCat.name}/>
                                                                                <div className="mod-category-lbl">{modCat.name}</div>
                                                                            </div>
                                                                            <hr style={{'marginTop':'3px'}}/>
                                                                            <ul className="ul-mods">
                                                                                {carBuild.stg4_build.map(build => (
                                                                                    build.cat_id === modCat.id ? (
                                                                                        <li className="mod-item">
                                                                                            {build.name}
                                                                                        </li>
                                                                                    ):('')
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    ) : ('')
                                                                ))
                                                            ):('')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ):('')
                                )}
                                
                                <div className="text-muted mt-2">
                                    <i className="fa fa-exclamation-triangle mr-1" aria-hidden="true"></i>
                                    <b>Attenzione:</b> le modifiche sono destinate esclusivamente all'utilizzo sportivo
                                    in pista o spazi privati.
                                </div>
                                <div className="text-muted mt-2">
                                    Non trovi la tua auto? <a href="/contactus">Contattaci</a>
                                </div>
                            {/*     </>)
                            )} */}
                        </div>
                    </div>
                </div>
            </div>
            {(cookie === undefined) ? <Footer /> : ''}
        </div>
    );
    
}

export default HpCalculator;
