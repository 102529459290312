import {useSpring, animated} from 'react-spring';

function NumberAnimated ({n}){
    const {number} = useSpring({
        from: {number: 0},
        number: n,
        delay: 200,
        config: {mass: 1, tension: 20, friction: 10}
    });

    return (
        <div className="display-cv">
            <animated.div className="cv-font">
                {number.to((n) => n.toFixed(0))}
            </animated.div>
            &nbsp;cv
        </div>
        );
}

export default NumberAnimated;