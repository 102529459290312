import React from "react";
import PropTypes from 'prop-types';

export default class CustomInput extends React.Component{

   getLabelAddon(){
      if(this.props.label.length > 0){
         return(
               <label className="input-group-text">
                  {this.props.label}
               </label>
         );
      }else{
         return (false);
      }
   }

   render(){
      var labelAddon = this.getLabelAddon();
         return (
                 <div className="input-group">
                    {labelAddon ? (
                    <div className="input-group-prepend">
                        {labelAddon}
                     </div>
                     ) : ('')}
                     <input
                        name= {this.props.name}
                        type = {this.props.type} 
                        value = {this.props.value} 
                        placeholder = {this.props.placeholder}
                        className = "form-control"
                        style = {this.props.style}
                        onChange = {this.props.onChange.bind(this)}
                        disabled = {this.props.disabled}
                     />
                 </div>
               );
   }
}

CustomInput.propTypes = {
   name: PropTypes.string,
   type: PropTypes.string,
   value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
   placeholder: PropTypes.string,
   style: PropTypes.object,
   onChange: PropTypes.func.isRequired,
   label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ])
}

CustomInput.defaultProps = {
   type: "text",
   label:""
}