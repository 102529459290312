import React from "react";
import RegisterService from "./registerService";
import CustomInput from "../../common/input/customInput";
import CryptoJS from 'crypto-js/aes';
import Spinner from 'react-bootstrap/Spinner';
import RaceLogo from '../../images/logo-gray-track.png';
import BgVideo from '../../videos/Bg-video-tuner.mp4';

export default class Register extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            username: '',
            email: '',
            password: '',
            password2: '',
            feedback: '',
            feedback_class: '',
            isSubmitted: false
        }
        this.registerService = new RegisterService();
        this.errors='';
    }

    changeText(field, event){
        this.setState({[field]:event.target.value.trim()});
    }

    checkPasswords(){
        //Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (#?!@$%^&*-)
        const pwReg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if(pwReg.test(this.state.password)){
            if(this.state.password === this.state.password2){
                return true;
            }else{
                this.errors = 'Le due password non corrispondono';
            }
        }else{
            this.errors = 'La password deve contenere almeno 8 caratteri, di cui almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale tra questi: #?!@$%^&*-';
        }
    }

    onSubmit(event){
        event.preventDefault();
    }

    registerSuccess(dataResult){
        this.setState({feedback: 'Clicca nel link che ti abbiamo mandato via mail per confermare il tuo account, poi accedi', feedback_class: 'alert-success'});
    }

    registerError(errorData){
        this.setState({feedback: errorData, feedback_class: 'alert-danger', isSubmitted: false});
    }

    validateEmail(email) {
        const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(String(email).toLowerCase());
    }

    checkUsernamePattern(username) {
        let usernamePattern = /^[a-zA-Z0-9_.]*$/;
        return usernamePattern.test(username);
    }

    register(event){
        if(this.state.username!=='' && this.state.email!=='' && this.state.password!=='' && this.state.password2!==''){
            if(this.checkUsernamePattern(this.state.username)){
                if(this.state.username.length > 20){
                    if(this.validateEmail(this.state.email)){
                        if(this.checkPasswords()){
                            var encryptedPW = CryptoJS.encrypt(this.state.password, process.env.REACT_APP_CRYPT_KEY).toString();
                            this.setState({isSubmitted: true});
                            this.registerService.register(this.state.username, this.state.email, encryptedPW, this.registerSuccess.bind(this), this.registerError.bind(this));
                        }else{
                            this.setState({feedback: this.errors, feedback_class: 'alert-danger'});
                        }
                    }else{
                        this.setState({feedback: 'Email non corretta', feedback_class: 'alert-danger'});
                    }
                }else{
                    this.setState({feedback: "L'username non può superare i 20 caratteri", feedback_class: 'alert-danger'});
                }
            }else{
                this.setState({feedback: "L'username può contenere solo lettere, numeri, underscore e punti", feedback_class: 'alert-danger'});
            }
        }else{
            this.setState({feedback: 'Inserisci i campi obbligatori', feedback_class: 'alert-danger'});
        }
    }
    
    render(){
        return(
            <div className="main">
                <video id="backVideo" src={BgVideo} autoPlay loop muted />
                <div className="video-overlay"></div>
                <div className="main-box">
                    <div className = "container">
                        <div className = "row">
                            <div className = "accessForm col-md-6 mr-auto ml-auto">
                                <img src={RaceLogo} className="col-md-12 mr-auto ml-auto mb-3" width={'400px'} alt="race-logo"></img>
                                <h1 className="title-login">Le elaborazioni a carte scoperte.</h1>
                                <form onSubmit={this.onSubmit.bind(this)}>
                                    <div className = "form-group">
                                        <CustomInput 
                                            type="text"
                                            //label="username"
                                            placeholder="username"
                                            value = {this.state.username || ''}
                                            onChange = {this.changeText.bind(this, "username")}
                                            />
                                    </div>
                                    <div className = "form-group">
                                        <CustomInput 
                                            type="email"
                                            //label="email"
                                            placeholder="email"
                                            value = {this.state.email || ''}
                                            onChange = {this.changeText.bind(this, "email")}
                                            />
                                    </div>
                                    <div className = "form-group">
                                        <CustomInput 
                                        type="password"
                                        //label="password"
                                        placeholder="password"
                                        value = {this.state.password}
                                        onChange = {this.changeText.bind(this, "password")}
                                        />
                                    </div>
                                    <div className = "form-group">
                                        <CustomInput 
                                        type="password"
                                        //label="password"
                                        placeholder="ripeti password"
                                        value = {this.state.password2}
                                        onChange = {this.changeText.bind(this, "password2")}
                                        />
                                    </div>
                                    {!this.state.isSubmitted ? (
                                        <button 
                                            type="submit"
                                            className = "btn btn-primary mb-3"
                                            onClick={this.register.bind(this)}>
                                            Registrati
                                        </button>
                                    ) : (
                                        this.state.feedback === '' ? (
                                        <Spinner className="disc-name-color" animation="border" role="status">
                                            <span className="d-none">Loading...</span>
                                        </Spinner>) : ('')
                                    )}
                                </form>
                                {this.state.feedback ? (
                                    <div className={"alert "+this.state.feedback_class}>
                                        {this.state.feedback}
                                    </div>
                                ):('')}
                                <div className="row col-md-12"><a href="/login">Hai un account? Accedi</a></div>
                                <div className="row col-md-12"><a href="/contactus">Contattaci</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}