import CryptoJS from 'crypto-js/aes';

export const getHeadersAuthorization = (value) => {
    
    //questi servono solo per confondere e non far capire che la vera Auth è nel campo 'User-Agent-v'
    let conf1 = CryptoJS.encrypt(Math.random().toString(36).substring(7), process.env.REACT_APP_CRYPT_KEY).toString();
    let conf2 = CryptoJS.encrypt(Math.random().toString(36).substring(7), process.env.REACT_APP_CRYPT_KEY).toString();
    
    return {
      'User-Agent-v': value,
      'x-api-key': conf1,
      'Authorization': conf2
    };
  };