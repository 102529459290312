import React, { Component } from 'react';

export default class ImagesUpload extends Component {

  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props)
    this.state = {
      file: []
    }
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
    this.uploadFiles = this.uploadFiles.bind(this)
    this.deleteImages = this.deleteImages.bind(this)
  }

  uploadMultipleFiles(e) {
    this.fileObj.push(e.target.files);

    this.fileArray = [];
    for (let i = 0; i < this.fileObj.length; i++) {
      for (let z = 0; z < this.fileObj[i].length; z++) {
        this.fileArray.push(URL.createObjectURL(this.fileObj[i][z]))
      }
    }

    this.setState({ file: this.fileArray })
    this.props.onEdit(this.fileObj);
  }

  uploadFiles(e) {
    //console.log(this.state.file)
  }

  deleteImages(e) {
    this.fileObj = [];
    this.fileArray = [];
    this.setState({ file: [] })
    this.props.onEdit([]);
  }

  render() {
    return (
      <div>
        <div className="form-group">
          <div className="custom-file">
            <input type="file" name="file" className="custom-file-input" accept="image/*" onChange={this.uploadMultipleFiles} multiple />
            <label className="custom-file-label">Carica una o più immagini...</label>
          </div>
        </div>
        
        <div className="form-group">
          <div className="multi-preview">
            {(this.state.file || []).map(url => (
              <img key={url} className="upload-preview preview-fitted" width="200px" src={url} alt="..." />
            ))}
          </div>
        </div>
        {(this.state.file.length > 0 ? (
          <div className='form-title'>
            <button type="button" className="btn btn-danger" onClick={this.deleteImages}>X</button>
          </div>
        ) : (''))}
      </div>
    )
  }
}

/* <button type="button" className="btn btn-danger btn-block" onClick={(event)=>this.props.onEdit(event)}>Upload</button> */