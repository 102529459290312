import React, {useState, useEffect} from "react";
import axios from "axios";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js/aes';
import DecryptUtf8 from 'crypto-js/enc-utf8';
import Header from "../mainLayout/header/header";
import Footer from "../mainLayout/footer/footer";
import "../../css/home/hpcalculator.css";
import CustomInput from "../../common/input/customInput";
import Spinner from 'react-bootstrap/Spinner';
import chipLogo from '../../images/category_icons/chip.png';
import intakeLogo from '../../images/category_icons/intake.png';
import coiloverLogo from '../../images/category_icons/coilover.png';
import exhaustLogo from '../../images/category_icons/exhaust.png';
import turboLogo from '../../images/category_icons/turbo.png';
import sparkLogo from '../../images/category_icons/sparkplug.png';
import utilityLogo from '../../images/category_icons/utility.png';
import intercoolerLogo from '../../images/category_icons/intercooler.png';
import brakeLogo from '../../images/category_icons/brake.png';
import pistonLogo from '../../images/category_icons/piston.png';
import { getHeadersAuthorization } from '../utilityFunctions/apiheadersauthorization';

function ElaborationSteps (props){

    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [engines, setEngines] = useState([]);
    const [selectedModel, setSelectedModel] = useState(0);
    const [selectedEngine, setSelectedEngine] = useState(0);
    const [mods, setMods] = useState([]);
    const [modsCategories, setModsCategories] = useState([]);
    const [cv1, setCv1] = useState(0);
    const [cv2, setCv2] = useState(0);
    const [cv3, setCv3] = useState(0);
    const [cv4, setCv4] = useState(0);
    const [stg1Mods, setstg1Mods] = useState([]);
    const [stg2Mods, setstg2Mods] = useState([]);
    const [stg3Mods, setstg3Mods] = useState([]);
    const [stg4Mods, setstg4Mods] = useState([]);
    const [isAdmin, setIsAdmin] = useState('loading');
    const [feedback, setFeedback] = useState([]);
    const [feedbackType, setfeedbackType] = useState('');
    const [instagram, setInstagram] = useState('');
    const [showInstaField, setShowInstaField] = useState(false);

    let categoriesLogos = {
        1: chipLogo,
        2: intakeLogo,
        3: coiloverLogo,
        4: exhaustLogo,
        5: turboLogo,
        6: sparkLogo,
        7: utilityLogo,
        8: intercoolerLogo,
        9: brakeLogo,
        10: pistonLogo,
    }

    const cookies = new Cookies();
    let cookie = cookies.get('data');
    let decrypted = '';
    let uid = null;
    let config = null;

    if(cookie !== undefined){
        config = {
            headers: getHeadersAuthorization(cookie)
        };

        decrypted = CryptoJS.decrypt(cookie, process.env.REACT_APP_CRYPT_KEY).toString(DecryptUtf8);
        //data[0]=uid, data[1]=token, data[2]=username
        uid = decrypted.split('%')[0];
    }

    useEffect(() => {
        const toPost={uid: uid};
        axios.post(process.env.REACT_APP_API_HOST + "user/isadmin", toPost, config)
        .then((response) => {
            setIsAdmin(response.data.items);
        });

        axios.post(process.env.REACT_APP_API_HOST + "car/getallbrands", [], config)
        .then((response) => {
            setBrands(response.data.items);
        }).catch((error) => {
            /* if (error.response.status === 401) {
                //devo ri-instanziare Cookies perché ancora non esiste
                cookies.remove('data', { path: '/' });
                window.location.replace("/login");
            } */
        });

        axios.post(process.env.REACT_APP_API_HOST + "car/getallmods", [], config)
        .then((response) => {
            setMods(response.data.items);
        }).catch((error) => {
            /* if (error.response.status === 401) {
                //devo ri-instanziare Cookies perché ancora non esiste
                cookies.remove('data', { path: '/' });
                window.location.replace("/login");
            } */
        });

        axios.post(process.env.REACT_APP_API_HOST + "car/getallmodscategories", [], config)
        .then((response) => {
            setModsCategories(response.data.items);
        }).catch((error) => {
            /* if (error.response.status === 401) {
                //devo ri-instanziare Cookies perché ancora non esiste
                cookies.remove('data', { path: '/' });
                window.location.replace("/login");
            } */
        });

        axios.post(process.env.REACT_APP_API_HOST + "user/getuser", toPost, config)
        .then((response) => {
            let instaName = !response.data.items[0].insta_name ?? true;
            setShowInstaField(instaName);
        }).catch((error) => {});

    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();
        const toPost={
            uid: parseInt(uid),
            model_id: selectedModel,
            engine_id: selectedEngine,
            stg1_build: stg1Mods.toString(),
            stg2_build: stg2Mods.toString(),
            stg3_build: stg3Mods.toString(),
            stg4_build: stg4Mods.toString(),
            stg1_cv: parseFloat(cv1),
            stg2_cv: parseFloat(cv2),
            stg3_cv: parseFloat(cv3),
            stg4_cv: parseFloat(cv4),
            instagram: instagram
        };

        let errors = false;
        let errorsDetail = [];

        if(selectedModel === 0 || selectedModel === 0){
            errors = true;
            errorsDetail.push("Compila marca, modello e motorizzazione");
        }

        if(toPost.stg1_build.length <=0 && toPost.stg2_build.length <=0 &&
            toPost.stg3_build.length <=0 && toPost.stg4_build.length <=0){
            errors = true;
            errorsDetail.push("Compila almeno uno stage per poter inviare l'elaborazione");
        } else {
            if(toPost.stg1_build.length > 0 && toPost.stg1_cv === 0){
                errors = true;
                errorsDetail.push("Compila i cavalli raggiungibili dello Stage 1");
            }
            else if(toPost.stg1_build.length <= 0 && toPost.stg1_cv > 0) {
                toPost.stg1_cv = 0;
            }
    
            if(toPost.stg2_build.length > 0 && toPost.stg2_cv === 0){
                errors = true;
                errorsDetail.push("Compila i cavalli raggiungibili dello Stage 2");
            }
            else if(toPost.stg2_build.length <= 0 && toPost.stg2_cv > 0) {
                toPost.stg2_cv = 0;
            }
    
            if(toPost.stg3_build.length > 0 && toPost.stg3_cv === 0){
                errors = true;
                errorsDetail.push("Compila i cavalli raggiungibili dello Stage 3");
            }
            else if(toPost.stg3_build.length <= 0 && toPost.stg3_cv > 0) {
                toPost.stg3_cv = 0;
            }
    
            if(toPost.stg4_build.length > 0 && toPost.stg4_cv === 0){
                errors = true;
                errorsDetail.push("Compila i cavalli raggiungibili dello Stage 4");
            }
            else if(toPost.stg4_build.length <= 0 && toPost.stg4_cv > 0) {
                toPost.stg4_cv = 0;
            }
        }

        if(errors) {
            setFeedback(errorsDetail);
            setfeedbackType("alert-danger");
        }


        if(!errors){
            axios.post(process.env.REACT_APP_API_HOST + "car/insertcarbuilds", toPost, config)
            .then((response) => {
                if(response.data.response==="SUCCESS"){
                    setFeedback(["Dati inseriti con successo! L'elaborazione è in fase di revisione, riceverai una mail quando verrà approvata"]);
                    setfeedbackType("alert-success");
                    setTimeout(function(){ window.location.replace("/elaborationstages");}, 2000);
                }else{
                    setFeedback(["Errore durante l'inserimento dei dati"]);
                    setfeedbackType("alert-danger");
                }
            }).catch((error) => {});
        }
    }
    
    const onSelectedBrand = (event) => {

        const toPost={
            brand_id: event.target.value
        };

        axios.post(process.env.REACT_APP_API_HOST + "car/getbrandmodels", toPost, config)
        .then((response) => {
            setModels(response.data.items);
        }).catch((error) => {
            /* if (error.response.status === 401) {
                //devo ri-instanziare Cookies perché ancora non esiste
                cookies.remove('data', { path: '/' });
                window.location.replace("/login");
            } */
        });
    }

    const onSelectedModel = (event) => {

        const toPost={
            model_id: event.target.value
        };
        setSelectedModel(toPost.model_id);

        axios.post(process.env.REACT_APP_API_HOST + "car/getmodelengines", toPost, config)
        .then((response) => {
            setEngines(response.data.items);
        }).catch((error) => {
            /* if (error.response.status === 401) {
                //devo ri-instanziare Cookies perché ancora non esiste
                cookies.remove('data', { path: '/' });
                window.location.replace("/login");
            } */
        });
    }

    const onSelectedEngine = (event) => {
        setSelectedEngine(event.target.value);
    }

    const onCv1Change = (event) => {
        setCv1(event.target.value);
    }

    const onCv2Change = (event) => {
        setCv2(event.target.value);
    }

    const onCv3Change = (event) => {
        setCv3(event.target.value);
    }

    const onCv4Change = (event) => {
        setCv4(event.target.value);
    }

    const onInstagramChange = (event) => {
        //se c'è la chiocciola, la levo
        setInstagram(event.target.value.replace('@',''));
    }

    const onStg1Check = (event) => {
        let value = event.target.value;
        let checked = event.target.checked;
    
        addRemoveToStageModsList(1, value, checked);
        //riporto su stage2
        /* document.getElementById('stg2-'+value).checked = checked;
        addRemoveToStageModsList(2, value, checked);
        //riporto su stage3
        document.getElementById('stg3-'+value).checked = checked;
        addRemoveToStageModsList(3, value, checked);
        //riporto su stage4
        document.getElementById('stg4-'+value).checked = checked;
        addRemoveToStageModsList(4, value, checked); */
    }

    const onStg2Check = (event) => {
        let value = event.target.value;
        let checked = event.target.checked;

        addRemoveToStageModsList(2, value, checked);
        //riporto su stage3
        /* document.getElementById('stg3-'+value).checked = checked;
        addRemoveToStageModsList(3, value, checked);
        //riporto su stage4
        document.getElementById('stg4-'+value).checked = checked;
        addRemoveToStageModsList(4, value, checked); */
    }

    const onStg3Check = (event) => {
        let value = event.target.value;
        let checked = event.target.checked;

        addRemoveToStageModsList(3, value, checked);
        //riporto su stage4
        /* document.getElementById('stg4-'+value).checked = checked;
        addRemoveToStageModsList(4, value, checked); */
    }

    const onStg4Check = (event) => {
        let value = event.target.value;
        let checked = event.target.checked;

        addRemoveToStageModsList(4, value, checked);
    }

    const addRemoveToStageModsList = (stage, newMod, add) => {
        newMod = parseInt(newMod);
        switch (stage){
            case 1:
                if(add){
                    let array1 = stg1Mods;
                    array1.push(newMod);
                    setstg1Mods(array1);
                } else {
                    let array1 = stg1Mods;
                    array1.splice(array1.indexOf(newMod));
                }
                break;
            case 2:
                if(add){
                    let array2 = stg2Mods;
                    array2.push(newMod);
                    setstg2Mods(array2);
                } else {
                    let array2 = stg2Mods;
                    array2.splice(array2.indexOf(newMod));
                }
                break;
            case 3:
                if(add){
                    let array3 = stg3Mods;
                    array3.push(newMod);
                    setstg3Mods(array3);
                } else {
                    let array3 = stg3Mods;
                    array3.splice(array3.indexOf(newMod));
                }
                break;
            case 4:
                if(add){
                    let array4 = stg4Mods;
                    array4.push(newMod);
                    setstg4Mods(array4);
                } else {
                    let array4 = stg4Mods;
                    array4.splice(array4.indexOf(newMod));
                }
                break;
            default:
                break;
        }
    }

    return(
        <div>
            {(cookie === undefined) ? <Header /> : ''}
            <div className="main-box">
                <div className = "container">
                    <div className = "row">
                        <div className = "col-lg-6 col-md-12 mr-auto ml-auto">
                            {isAdmin === 'loading' ? (
                                    <Spinner className="ml-2 disc-name-color" animation="border" role="status">
                                        <span className="d-none">Loading...</span>
                                    </Spinner>
                                ):(
                                    !isAdmin ? window.location.replace('/') : (
                                    <>
                                    <a href="/admin"><i className="fa fa-arrow-left fa-lg"></i></a>
                                    <form onSubmit={onSubmit.bind(this)}>
                                        <h2 className="form-title mb-2">Imposta step elaborazione</h2>
                                        <div className="text-muted form-title mb-4">Utilizza questa pagina per impostare i vari stage di elaborazione delle vetture.</div>
                                        <div className = "form-group">
                                            <select className="form-control" id="" onChange={onSelectedBrand.bind(this)} defaultValue={'select'}>
                                                <option value="select" disabled>Scegli la marca</option>
                                                {brands.map(brand => (
                                                    <option key={brand.id} value={brand.id}>{brand.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className = "form-group">
                                            <select className="form-control" id="" onChange={onSelectedModel.bind(this)} defaultValue={'select'}>
                                                <option value="select" disabled>Scegli il modello</option>
                                                {models.map(model => (
                                                    <option key={model.id} value={model.id}>{model.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className = "form-group">
                                            <select className="form-control" id="" onChange={onSelectedEngine.bind(this)} defaultValue={'select'}>
                                                <option value="select" disabled>Scegli la motorizzazione</option>
                                                {engines.map(engine => (
                                                    <option key={engine.id} value={engine.id}>{engine.name}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <nav className="mt-4">
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button className="nav-link active" id="nav-stg1-tab" data-toggle="tab" data-target="#nav-stg1" type="button" role="tab" aria-controls="nav-stg1" aria-selected="true">Stage 1</button>
                                                <button className="nav-link" id="nav-stg2-tab" data-toggle="tab" data-target="#nav-stg2" type="button" role="tab" aria-controls="nav-stg2" aria-selected="false">Stage 2</button>
                                                <button className="nav-link" id="nav-stg3-tab" data-toggle="tab" data-target="#nav-stg3" type="button" role="tab" aria-controls="nav-stg3" aria-selected="false">Stage 3</button>
                                                <button className="nav-link" id="nav-stg4-tab" data-toggle="tab" data-target="#nav-stg4" type="button" role="tab" aria-controls="nav-stg4" aria-selected="false">Stage 4</button>
                                            </div>
                                        </nav>

                                        <div className="tab-content" id="nav-tabContent">
                                            {showInstaField ? (
                                                <div className="insta_name_wrapper">
                                                    <div className="insta_name_input">
                                                        <CustomInput 
                                                            type="text"
                                                            name="insta_name"
                                                            label={[<i className="fa fa-instagram"></i>]}
                                                            placeholder="nome Instagram (senza @)"
                                                            value = {instagram || ''}
                                                            onChange = {onInstagramChange.bind(this)}
                                                            style={{'width':'60%'}}
                                                        />
                                                    </div>
                                                </div>
                                            ):('')}
                                            <div className="tab-pane fade show active mt-3 mb-4" id="nav-stg1" role="tabpanel" aria-labelledby="nav-stg1-tab">
                                                <div className="mods-container">
                                                    {modsCategories.map(modCat => (
                                                        <div key={modCat.id} className="category-mod-item">
                                                            <div className="mod-category-title">
                                                                <img className="cat-logos-size" src={categoriesLogos[modCat.id]} alt={modCat.name}/>
                                                                <div className="mod-category-lbl">{modCat.name}</div>
                                                            </div>
                                                            <hr style={{'marginTop':'3px'}}/>
                                                            <ul className="ul-mods">
                                                                {mods.map(mod => (
                                                                    mod.category_id === modCat.id ? (
                                                                        <li key={mod.id} style={{'color':'black'}}>
                                                                            <input type="checkbox" className="form-check-input" 
                                                                                id={"stg1-"+mod.id} 
                                                                                name={"stg1-"+mod.id} 
                                                                                value={mod.id} 
                                                                                onChange={onStg1Check.bind(this)}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={"stg1-"+mod.id}>{mod.name}</label>
                                                                        </li>
                                                                    ):('')
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                                <CustomInput 
                                                    type="number"
                                                    name="cv-stg1"
                                                    label="CV"
                                                    placeholder="Cavalli raggiungibili con lo stage 1"
                                                    value = {cv1 || ''}
                                                    onChange = {onCv1Change.bind(this)}
                                                />
                                            </div>
                                            <div className="tab-pane fade mt-3 mb-4" id="nav-stg2" role="tabpanel" aria-labelledby="nav-stg2-tab">
                                                <div className="mods-container">
                                                    {modsCategories.map(modCat => (
                                                        <div key={modCat.id} className="category-mod-item">
                                                            <div className="mod-category-title">
                                                                <img className="cat-logos-size" src={categoriesLogos[modCat.id]} alt={modCat.name}/>
                                                                <div className="mod-category-lbl">{modCat.name}</div>
                                                            </div>
                                                            <hr style={{'marginTop':'3px'}}/>
                                                            <ul className="ul-mods">
                                                                {mods.map(mod => (
                                                                    mod.category_id === modCat.id ? (
                                                                        <li key={mod.id} style={{'color':'black'}}>
                                                                            <input type="checkbox" className="form-check-input" 
                                                                                id={"stg2-"+mod.id} 
                                                                                name={"stg2-"+mod.id} 
                                                                                value={mod.id} 
                                                                                onChange={onStg2Check.bind(this)}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={"stg2-"+mod.id}>{mod.name}</label>
                                                                        </li>
                                                                    ):('')
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                                <CustomInput 
                                                    type="number"
                                                    name="cv-stg2"
                                                    label="CV"
                                                    placeholder="Cavalli raggiungibili con lo stage 2"
                                                    value = {cv2 || ''}
                                                    onChange = {onCv2Change.bind(this)}
                                                />
                                            </div>
                                            <div className="tab-pane fade mt-3 mb-4" id="nav-stg3" role="tabpanel" aria-labelledby="nav-stg3-tab">
                                                <div className="mods-container">
                                                    {modsCategories.map(modCat => (
                                                        <div key={modCat.id} className="category-mod-item">
                                                            <div className="mod-category-title">
                                                                <img className="cat-logos-size" src={categoriesLogos[modCat.id]} alt={modCat.name}/>
                                                                <div className="mod-category-lbl">{modCat.name}</div>
                                                            </div>
                                                            <hr style={{'marginTop':'3px'}}/>
                                                            <ul className="ul-mods">
                                                                {mods.map(mod => (
                                                                    mod.category_id === modCat.id ? (
                                                                        <li key={mod.id} style={{'color':'black'}}>
                                                                            <input type="checkbox" className="form-check-input" 
                                                                                id={"stg3-"+mod.id} 
                                                                                name={"stg3-"+mod.id} 
                                                                                value={mod.id} 
                                                                                onChange={onStg3Check.bind(this)}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={"stg3-"+mod.id}>{mod.name}</label>
                                                                        </li>
                                                                    ):('')
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                                <CustomInput 
                                                    type="number"
                                                    name="cv-stg3"
                                                    label="CV"
                                                    placeholder="Cavalli raggiungibili con lo stage 3"
                                                    value = {cv3 || ''}
                                                    onChange = {onCv3Change.bind(this)}
                                                />
                                            </div>
                                            <div className="tab-pane fade mt-3 mb-4" id="nav-stg4" role="tabpanel" aria-labelledby="nav-stg4-tab">
                                                <div className="mods-container">
                                                    {modsCategories.map(modCat => (
                                                        <div key={modCat.id} className="category-mod-item">
                                                            <div className="mod-category-title">
                                                                <img className="cat-logos-size" src={categoriesLogos[modCat.id]} alt={modCat.name}/>
                                                                <div className="mod-category-lbl">{modCat.name}</div>
                                                            </div>
                                                            <hr style={{'marginTop':'3px'}}/>
                                                            <ul className="ul-mods">
                                                                {mods.map(mod => (
                                                                    mod.category_id === modCat.id ? (
                                                                        <li key={mod.id} style={{'color':'black'}}>
                                                                            <input type="checkbox" className="form-check-input" 
                                                                                id={"stg4-"+mod.id} 
                                                                                name={"stg4-"+mod.id} 
                                                                                value={mod.id} 
                                                                                onChange={onStg4Check.bind(this)}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={"stg4-"+mod.id}>{mod.name}</label>
                                                                        </li>
                                                                    ):('')
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                                <CustomInput 
                                                    type="number"
                                                    name="cv-stg4"
                                                    label="CV"
                                                    placeholder="Cavalli raggiungibili con lo stage 4"
                                                    value = {cv4 || ''}
                                                    onChange = {onCv4Change.bind(this)}
                                                />
                                            </div>
                                        </div>

                                        <button 
                                            type="submit"
                                            className = "btn btn-primary">
                                            Inserisci
                                        </button>
                                    </form>
                                    {feedback.map((fb, i) => (
                                        <div key={i} className={"alert "+ feedbackType}>
                                            {fb}
                                        </div>
                                    ))}
                                    </>)
                                )}
                        </div>
                    </div>
                </div>
            </div>
            {(cookie === undefined) ? <Footer /> : ''}
        </div>
    );
    
}

export default ElaborationSteps;
